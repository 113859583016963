/**
 *
 * Building Fabric Module JavaScript
 *
 */

// Calculation selector
import './calculation-selector';

// Module input validation
import './validation';

// Whole building form
import './whole-building.js';

// Individual elements form
import './individual-elements.js';

const body = $("body");

//Toggle input form
body.on('click', '.module-form[data-module=buildingFabricWholeBuilding] .toggle-building-fabric-window-add-unit-form, .module-form[data-module=buildingFabricIndividualElements] .toggle-add-unit-form', function(e) {
    const form = $(this).closest(".unit-input-container").find(".unit-input-form");

    //Get state of form
    const isOpen = form.hasClass("open");

    if(isOpen){
        //Slide up
        form.slideUp("slow");

        //Close form
        form.removeClass("open");
    } else {
        //Slide down
        form.slideDown("slow");

        //Open form
        form.addClass("open");
    }
});

//Toggle open assumption fields
body.on('click', '.module-form[data-module=buildingFabricWholeBuilding] .edit-assumptions-button, .module-form[data-module=buildingFabricIndividualElements] .edit-assumptions-button', function(e) {
    const fields = $(this).closest(".input-form").find(".assumption-fields");

    //Get state of form
    const isOpen = fields.hasClass("open");

    if(isOpen){
        //Slide up
        fields.slideUp("fast", function(){
            //Close form
            fields.removeClass("open");
        });

        rotate($(this).find("svg"), 0)
    } else {
        //Slide down
        fields.slideDown("fast", function() {
            //Open form
            fields.addClass("open");

            //Clear inline style
            fields.removeAttr("style");
        });

        rotate($(this).find("svg"), 180)
    }
});


function rotate(object, degree) {
    object.css({WebkitTransform: 'rotate(' + degree + 'deg)'});
    object.css({'-moz-transform': 'rotate(' + degree + 'deg)'});
}