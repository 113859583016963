import Swiper, {Manipulation} from "swiper";
import {buildingFabricWholeBuilding, buildingFabricIndividualElements} from "@/js/modules/building-fabric/validation";

export function checkTechnologyData(moveToDetails = false){
    //No technologies
    if($(".technology-nav .slide-container .item").length < 1){
        //Update error message
        $(".actions-bar-container .actions-bottom-bar .message-bar .error-alert p").text("At least one technology must be selected.");

        //Show error message
        $(".actions-bar-container .actions-bottom-bar .message-bar").removeClass("hide");

        //Disable next button
        $(".actions-bar-container .actions-bottom-bar .next").prop("disabled", true);
        return;
    }

    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            data: JSON.stringify({
                action: "technologyCheck"
            })
        },
        success: function(data) {
            if(data.allFilled === false){
                //Get the first technology's name
                let techName = $(".technology-nav .slide-container .swiper-slide .item[data-technology='" + data.missingTechnologies[0] + "'] h2").text();

                //Remove any errors from technology slider nav
                $(".technology-nav .slide-container .swiper-slide .item.error").removeClass("error");

                //Add error class to all empty techs
                const emptyTechCount = data.missingTechnologies.length;
                for(let i = 0; i < emptyTechCount; i++){
                    $(".technology-nav .slide-container .swiper-slide .item[data-technology='" + data.missingTechnologies[i] + "']").addClass("error");
                }

                if(emptyTechCount > 1){
                    //Update error message
                    $(".actions-bar-container .actions-bottom-bar .message-bar .error-alert p").text("Multiple technologies are empty. Enter data into all technologies to continue.");
                } else {
                    //Update error message
                    $(".actions-bar-container .actions-bottom-bar .message-bar .error-alert p").text(techName + " is empty. Enter data into the empty technology to continue.");
                }

                //Show error message
                $(".actions-bar-container .actions-bottom-bar .message-bar").removeClass("hide");

                //Disable next button
                $(".actions-bar-container .actions-bottom-bar .next").prop("disabled", true);
            } else {
                //Hide error message
                $(".actions-bar-container .actions-bottom-bar .message-bar").addClass("hide");

                //Enable next button
                $(".actions-bar-container .actions-bottom-bar .next").prop("disabled", false);

                //Remove any errors from technology slider nav
                $(".technology-nav .slide-container .swiper-slide .item.error").removeClass("error");


                //Redirect user
                if(moveToDetails){
                    loadContent("details?headless=true", "./details", "Details - Savings Calculator WhatsThePayback");
                }
            }
        }
    });
}
window.checkTechnologyData = checkTechnologyData;

export function loadTechnologyPage(id){
    if(typeof $ !== "undefined"){
        $(".panel.page-content").replaceWith("<div class='panel page-content'><div class='page-loader'></div><h1 class='page-loader-title'>Loading page...</h1></div>");

        $.get("./technologies?id=" + id, function(data, status){
            $(".panel.page-content").replaceWith(data);

            //Has v2 stages menu, center active stage button
            if($(".form-progress-v2 .stages .stage-button.active").length > 0){
                centerFormProgressV2ActiveStage();
            }
        });
    }
}
window.loadTechnologyPage = loadTechnologyPage;

export function boilerInputFieldWizard(){

    let erroredInputs = 0;

    //Remove any pre-existing error classes
    $(".boilerInputForm .subpage .input").removeClass("error").removeClass("error-disabled");

    //Get form inputs
    let form = $(".boilerInputForm .subpage.active");
    let inputs = $(form).find(".input");

    inputs.each(function(){
        let type;
        let empty;

        //Find the inputs type (field or dropdown)
        if($(this).find("input").length > 0){
            type = "field";
        } else {
            type = "dropdown";
        }

        //Check if the input is empty
        switch(type){
            case "dropdown":
                empty = $(this).find(".button .selected").text() === "Select an option";
                break;
            case "field":
            default:
                empty = $(this).find("input").val() === "";
                break;
        }


        //Set the correct error class (if any)
        if(empty && !$(this).hasClass("allow-empty")){
            //Check if this is the first input errored (user will input a value here next)
            if(erroredInputs === 0){
                $(this).addClass("error");
            } else {
                $(this).addClass("error-disabled");
            }
            erroredInputs++;
        }

    });
}
window.boilerInputFieldWizard = boilerInputFieldWizard;


export function loadContent(url, backUrl, title, callback = null){
    loadingPage = true;
    $(".loader").removeClass("hidden");
    $("#content").hide();
    $.get(url, function(data, status){
        loadingPage = false;
        $(".loader").addClass("hidden");
        $("#content").show();
        if (status === "success"){
            $("#content").replaceWith(data);
            window.history.pushState('page', title, backUrl);

            //Create Swiper if technology input page
            if(url === "technologies?headless=true"){
                let technologySlider = new Swiper('.slide-container', {
                    slidesPerView: "auto",
                    spaceBetween: 14,
                    navigation: {
                        nextEl: ".next-slide",
                        prevEl: ".previous-slide",
                    },
                    modules: [Manipulation]
                });
            }

            if(callback !== null) callback();
        } else {
            alert("Failed to load page")
        }
    });
}
window.loadContent = loadContent;

export function moduleFormChangePage(pageNum){
    let nextPageExists = $(`.module-form .form-pages .page[data-page=${pageNum+1}]`).length;
    let previousPageExists = $(`.module-form .form-pages .page[data-page=${pageNum-1}]`).length;
    let module = $(".module-form").data("module");

    if(!nextPageExists && module !== "roofInsulation"){
        $(".module-form .form-actions .button.next").hide();
    } else {
        $(".module-form .form-actions .button.next").show();
    }

    if(!nextPageExists && module !== "roofInsulation"){
        $(".actions-bar-container .error-mask").removeClass("active");
    } else {
        $(".actions-bar-container .error-mask").addClass("active");
    }

    if(!previousPageExists){
        $(".module-form .form-actions .button.previous").hide();
    } else {
        $(".module-form .form-actions .button.previous").show();
    }

    //Hide current page
    $(`.module-form .form-pages .page.show`).removeClass("show");

    //Show next page
    $(`.module-form .form-pages .page[data-page=${pageNum}]`).addClass("show");

    //Update page progress
    $(`.module-form .form-progress .stage-button, .module-form .form-progress-v2 .stage-button`).removeClass("active");
    $(`.module-form .form-progress .stage-button[data-page=${pageNum}], .module-form .form-progress-v2 .stage-button[data-page=${pageNum}]`).addClass("active");

    //Update current page value
    $(`.module-form .form-pages`).data("current-page", pageNum);

    centerFormProgressV2ActiveStage();
}
window.moduleFormChangePage = moduleFormChangePage;

export function moduleFormInputPageChange(pageChange){
    let moduleForm = $(".module-form:visible");
    let currentPage = moduleForm.find(".form-pages").data("current-page");
    let nextPageExists = moduleForm.find(`.form-pages .page[data-page=${pageChange}]`).length;

    console.log("Page change: " + pageChange);


    if(!nextPageExists && moduleForm.data("module") !== "roofInsulation"){
        return;
    }

    console.log("Page change: " + pageChange);

    //Solar Module
    if(moduleForm.data("module") === "solarPower"){
        //Validation
        if($(".module-form .form-pages .page.show input[name=solarRadiation]").length){
            let value = $(".module-form .form-pages .page.show input[name=solarRadiation]").val();
            if(value <= 0 || value === ""){
                $(".module-form .form-pages .page.show input[name=solarRadiation]").closest(".input-field").addClass("error");
                return;
            } else {
                $(".module-form .form-pages .page.show input[name=solarRadiation]").closest(".input-field").removeClass("error");
            }
        }

        if($(".module-form .form-pages .page.show input[name=roofSize]").length){
            let value = $(".module-form .form-pages .page.show input[name=roofSize]").val();
            if(value <= 0 || value === ""){
                $(".module-form .form-pages .page.show input[name=roofSize]").closest(".input-field").addClass("error");
                return;
            } else {
                $(".module-form .form-pages .page.show input[name=roofSize]").closest(".input-field").removeClass("error");
            }
        }

        let roofSlopeSlider = $("#roofSlopeSlider");

        //Saving
        switch(currentPage){
            case 1: //Roof Slope
                moduleFormSaveField("roofSlope", roofSlopeSlider.val());
                $("#roofSlopeOverviewValue").text(roofSlopeSlider.val() + "°");
                break;
            case 2: //Shading
                moduleFormSaveField("shading", $("#shadingSlider").val());
                let shading = "";
                switch($("#shadingSlider").val()){
                    case "0":
                        shading = "Less than 20% of unobstructed sky";
                        break;
                    case "1":
                        shading = "20-60% of unobstructed sky";
                        break;
                    case "2":
                        shading = "60-80% of unobstructed sky";
                        break;
                    case "3":
                        shading = "More than 80% of unobstructed sky";
                        break;
                }
                $("#shadingOverviewValue").text(shading);
                break;
            case 3: //Solar Radiation
                moduleFormSaveField("solarRadiation", $("input[name=solarRadiation]").val());
                $("#solarRadiationOverviewValue").text($("input[name=solarRadiation]").val() + " kWh/kWp");
                break;
            case 4: //Roof Size
                moduleFormSaveField("roofArea", $("input[name=roofSize]").val());
                $("#roofSizeOverviewValue").text($("input[name=roofSize]").val() + " m²");
                break;
            case 5: //Roof Direction
                if($(".dropdown#roofDirection .button .selected").text() === "Select an option"){
                    $(".dropdown#roofDirection").addClass("error");
                    return;
                } else {
                    $(".dropdown#roofDirection").removeClass("error");
                }
                moduleFormSaveField("roofDirection", $(".dropdown#roofDirection .button .selected").text());
                $("#roofDirectionOverviewValue").text($(".dropdown#roofDirection .button .selected").text());
                break;
            case 6: //Adjustments
                const outputDegradationElement = $("#outputDegradation");

                //Output degradation
                if($("#outputDegradationToggle").prop('checked')){
                    const yearlyOutputDegradation = outputDegradationElement.val();
                    //Yearly output degradation
                    if(yearlyOutputDegradation === ""){
                        outputDegradationElement.closest(".input-field").addClass("error");
                        return;
                    }
                    moduleFormSaveField("outputDegradation", yearlyOutputDegradation);
                    $("#outputDegradationOverviewValue").text(yearlyOutputDegradation + "%");

                    //Show values section
                    $(".outputDegradationOverviewValues").show();

                    moduleFormSaveField("outputDegradationToggle", 1);
                } else {
                    //Hide values section
                    $(".outputDegradationOverviewValues").hide();

                    moduleFormSaveField("outputDegradationToggle", 0);
                }

                //Site energy consumption percentage
                if($("#siteEnergyConsumptionPercentageToggle").prop('checked')){
                    let exportTariffUnitRateElement = $("#exportTariffUnitRate");
                    const exportTariffUnitRate = exportTariffUnitRateElement.val()
                    const exportPercentageSlider = $("#exportPercentageSlider").val();

                    //Export tariff unit rate
                    if(exportTariffUnitRate === ""){
                        exportTariffUnitRateElement.closest(".input-field").addClass("error");
                        return;
                    }

                    moduleFormSaveField("exportTariffUnitRate", exportTariffUnitRate);
                    $("#exportTariffUnitRateOverviewValue").text("£" + exportTariffUnitRate.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) + "/kWh");

                    moduleFormSaveField("exportPercentageSlider", exportPercentageSlider);
                    $("#exportPercentageSliderOverviewValue").text(exportPercentageSlider + "%");

                    moduleFormSaveField("siteEnergyConsumptionPercentageToggle", 1);

                    //Show values section
                    $(".siteEnergyConsumptionPercentageOverviewValues").show();
                } else {
                    moduleFormSaveField("siteEnergyConsumptionPercentageToggle", 0);

                    //Hide values section
                    $(".siteEnergyConsumptionPercentageOverviewValues").hide();
                }
                break;
        }
    }

    //Roof Insulation Module
    if(moduleForm.data("module") === "roofInsulation"){

        //Validation
        if($(".module-form .form-pages .page.show input[name=roofArea]").length){
            let value = $(".module-form .form-pages .page.show input[name=roofArea]").val();
            if(value <= 0 || value === ""){
                $(".module-form .form-pages .page.show input[name=roofArea]").closest(".input-field").addClass("error");
                return;
            } else {
                $(".module-form .form-pages .page.show input[name=roofArea]").closest(".input-field").removeClass("error");
            }
        }

        //Saving
        switch(currentPage){
            case 1:
                if($(".dropdown#heatingType .button .selected").text() === "Select an option"){
                    $(".dropdown#heatingType").addClass("error");
                    return;
                } else {
                    $(".dropdown#heatingType").removeClass("error");
                }
                break;
            case 2: //Roof Type
                // moduleFormSaveField("roofType", $(".dropdown#roofType .button .selected").text());
                if($(".dropdown#roofType .button .selected").text() === "Select an option"){
                    $(".dropdown#roofType").addClass("error");
                    return;
                } else {
                    $(".dropdown#roofType").removeClass("error");
                }
                $("#roofTypeOverviewValue").text($(".dropdown#roofType .button .selected").text());
                break;
            case 3: //Insulation Thickness
                // moduleFormSaveField("insulationThickness", $("#insulationThicknessSlider").val());
                let text = "";
                switch($("#insulationThicknessSlider").val()){
                    case "0":
                        text = "0 mm";
                        break;
                    case "1":
                        text = "10 mm";
                        break;
                    case "2":
                        text = "50 mm";
                        break;
                    case "3":
                        text = "100 mm";
                        break;
                    case "4":
                        text = "150 mm";
                        break;
                    case "5":
                        text = "200 mm";
                        break;
                    case "6":
                        text = "250 mm";
                        break;
                    case "7":
                        text = "270 mm";
                        break;
                }
                $("#roofInsulationOverviewValue").text(text);
                break;
            case 4: //Roof Area
                // moduleFormSaveField("roofArea", $("input[name=roofArea]").val());
                $("#roofAreaOverviewValue").text($("input[name=roofArea]").val() + " m²");

                $.ajax({
                    type: 'POST',
                    url: window.location.href,
                    data: {
                        data: JSON.stringify({
                            action: "moduleForm",
                            module: $(".module-form").data("module"),
                            value: {
                                roofName: $("input[name=roofName]").val(),
                                heatingType: $(".dropdown#heatingType .button .selected").text(),
                                roofArea: $("input[name=roofArea]").val(),
                                roofInsulation: $("#insulationThicknessSlider").val(),
                                roofType: $(".dropdown#roofType .button .selected").text()
                            }
                        })
                    },
                    success: function(data) {
                        //Set current page to one
                        $(".module-form .form-pages").data("current-page", 1);


                        //Reset form fields
                        $("input[name=roofName]").val("");
                        $("input[name=roofArea]").val("");
                        $("#insulationThicknessSlider").val(0);
                        $(".dropdown#roofType .button .selected").text("Select an option");

                        //Set page and breadcrumb back to start
                        $(".module-form .form-progress .stage-button.active").removeClass("active");
                        $(".module-form .form-progress .stage-button").first().addClass("active");
                        $(".module-form .form-pages .page.show").removeClass("show");
                        $(".module-form .form-pages .page").first().addClass("show");

                        //Hide form
                        $(".embed-form").hide();

                        $("#lightsList tbody").append(data);
                        if($("#lightsList tbody tr").length > 0) {
                            $(".listNoData").remove();
                        }

                        checkTechnologyData();
                    }
                });
                return;
        }
    }

    //Heat Pump Module
    if(moduleForm.data("module") === "heatPump"){
        let hasErrors = false;
        let fields = {};

        //Saving
        switch(currentPage){
            case 1: //Property Details
                //Project Type
                if($(".dropdown#projectType .button .selected").text() === "Select an option" || $(".dropdown#projectType .button .selected").text() === ""){
                    $(".dropdown#projectType").addClass("error");
                    hasErrors = true;
                } else {
                    $(".dropdown#projectType").removeClass("error");
                    fields["projectType"] = $(".dropdown#projectType .button .selected").text();
                }
                $("#projectTypeOverviewValue").text($(".dropdown#projectType .button .selected").text())


                //Property Type
                if($(".dropdown#propertyType .button .selected").text() === "Select an option" || $(".dropdown#propertyType .button .selected").text() === ""){
                    $(".dropdown#propertyType").addClass("error");
                    hasErrors = true;
                } else {
                    $(".dropdown#propertyType").removeClass("error");
                    fields["propertyType"] = $(".dropdown#propertyType .button .selected").text();
                }
                $("#propertyTypeOverviewValue").text($(".dropdown#propertyType .button .selected").text());


                //Number of Bedrooms
                if($(".dropdown#bedroomNumber .button .selected").text() === "Select an option" || $(".dropdown#bedroomNumber .button .selected").text() === ""){
                    $(".dropdown#bedroomNumber").addClass("error");
                    hasErrors = true;
                } else {
                    $(".dropdown#bedroomNumber").removeClass("error");
                    fields["bedroomNumber"] = $(".dropdown#bedroomNumber .button .selected").text();
                }
                $("#bedroomNumberOverviewValue").text($(".dropdown#bedroomNumber .button .selected").text());


                //Property Age
                if($(".dropdown#propertyAge .button .selected").text() === "Select an option" || $(".dropdown#propertyAge .button .selected").text() === ""){
                    $(".dropdown#propertyAge").addClass("error");
                    hasErrors = true;
                } else {
                    $(".dropdown#propertyAge").removeClass("error");
                    fields["propertyAge"] = $(".dropdown#propertyAge .button .selected").text();
                }
                $("#propertyAgeOverviewValue").text($(".dropdown#propertyAge .button .selected").text());

                //Region
                if($(".dropdown#region .button .selected").text() === "Select an option" || $(".dropdown#region .button .selected").text() === ""){
                    $(".dropdown#region").addClass("error");
                    hasErrors = true;
                } else {
                    $(".dropdown#region").removeClass("error");
                    fields["region"] = $(".dropdown#region .button .selected").text();
                }
                $("#regionOverviewValue").text($(".dropdown#region .button .selected").text());

                //Government Subsidies
                if($(".dropdown#governmentSubsidies .button .selected").text() === "Select an option" || $(".dropdown#governmentSubsidies .button .selected").text() === ""){
                    $(".dropdown#governmentSubsidies").addClass("error");
                    hasErrors = true;
                } else {
                    $(".dropdown#governmentSubsidies").removeClass("error");
                    fields["governmentSubsidies"] = $(".dropdown#governmentSubsidies .button .selected").text();
                }
                $("#governmentSubsidiesOverviewValue").text($(".dropdown#governmentSubsidies .button .selected").text());

                if($(".dropdown#governmentSubsidies .button .selected").text() == "Other (Manually Enter Rate)"){
                    //User is using manual subsidies
                    if($(".module-form .form-pages .page.show input[name=manualSubsidies]").length){
                        let value = $(".module-form .form-pages .page.show input[name=manualSubsidies]").val();
                        if(value <= 0 || value === ""){
                            $(".module-form .form-pages .page.show input[name=manualSubsidies]").closest(".input-field").addClass("error");
                            hasErrors = true;
                        } else {
                            $(".module-form .form-pages .page.show input[name=manualSubsidies]").closest(".input-field").removeClass("error");
                            fields["manualSubsidies"] = value;
                        }
                        $("#manualSubsidiesOverviewValue").text("£" + value + "/kWh")
                    }
                }

                if(hasErrors){
                    return;
                } else {
                    moduleFormSaveFields(fields, 1, function(data){
                        if(!JSON.parse(data).valid){

                        }
                    });
                }
                break;
            case 2: //Consumption Data

                //Has report
                if($(".dropdown#hasReport .button .selected").text() === "Select an option"){
                    $(".dropdown#hasReport").addClass("error");
                    hasErrors = true;
                } else {
                    $(".dropdown#hasReport").removeClass("error");
                    moduleFormSaveField("hasReport", $(".dropdown#hasReport .button .selected").text());
                }
                $("#hasReportOverviewValue").text($(".dropdown#hasReport .button .selected").text())


                if($(".dropdown#hasReport .button .selected").text() == "Yes") {
                    //User has a report

                    //Heating and Hot Water Consumption
                    if ($(".module-form .form-pages .page.show input[name=annualHeatingHotWater]").length) {
                        let value = $(".module-form .form-pages .page.show input[name=annualHeatingHotWater]").val();
                        if (value <= 0 || value === "") {
                            $(".module-form .form-pages .page.show input[name=annualHeatingHotWater]").closest(".input-field").addClass("error");
                            hasErrors = true;
                        } else {
                            $(".module-form .form-pages .page.show input[name=annualHeatingHotWater]").closest(".input-field").removeClass("error");
                            moduleFormSaveField("annualHeatingHotWater", value);
                        }
                        $("#annualHeatingHotWaterOverviewValue").text(value + " kWh")
                    }
                }

                //Known Floor Area
                if($(".dropdown#knownFloorArea .button .selected").text() === "Select an option"){
                    $(".dropdown#knownFloorArea").addClass("error");
                    hasErrors = true;
                } else {
                    $(".dropdown#knownFloorArea").removeClass("error");
                    moduleFormSaveField("knownFloorArea", $(".dropdown#knownFloorArea .button .selected").text());
                }
                $("#knownFloorAreaOverviewValue").text($(".dropdown#knownFloorArea .button .selected").text())

                if($(".dropdown#knownFloorArea .button .selected").text() == "Yes"){
                    //User know floor area
                    if($(".module-form .form-pages .page.show input[name=floorArea]").length){
                        let value = $(".module-form .form-pages .page.show input[name=floorArea]").val();
                        if(value <= 0 || value === ""){
                            $(".module-form .form-pages .page.show input[name=floorArea]").closest(".input-field").addClass("error");
                            hasErrors = true;
                        } else {
                            $(".module-form .form-pages .page.show input[name=floorArea]").closest(".input-field").removeClass("error");
                            moduleFormSaveField("floorArea", value);
                        }
                        $("#floorAreaOverviewValue").text(value + " m²")
                    }
                }

                //Property Efficiency
                if($(".dropdown#propertyEfficiency .button .selected").text() === "Select an option"){
                    $(".dropdown#propertyEfficiency").addClass("error");
                    hasErrors = true;
                } else {
                    $(".dropdown#propertyEfficiency").removeClass("error");
                    moduleFormSaveField("propertyEfficiency", $(".dropdown#propertyEfficiency .button .selected").text());
                }
                $("#propertyEfficiencyOverviewValue").text($(".dropdown#propertyEfficiency .button .selected").text())

                //Heating Fuel
                if($(".dropdown#heatingFuel .button .selected").text() === "Select an option"){
                    $(".dropdown#heatingFuel").addClass("error");
                    hasErrors = true;
                } else {
                    $(".dropdown#heatingFuel").removeClass("error");
                    moduleFormSaveField("heatingFuel", $(".dropdown#heatingFuel .button .selected").text());
                }
                $("#heatingFuelOverviewValue").text($(".dropdown#heatingFuel .button .selected").text())

                //Heating System Flow Temperature
                if($(".dropdown#flowTemp .button .selected").text() === "Select an option"){
                    $(".dropdown#flowTemp").addClass("error");
                    hasErrors = true;
                } else {
                    $(".dropdown#flowTemp").removeClass("error");
                    moduleFormSaveField("flowTemp", $(".dropdown#flowTemp .button .selected").text());
                }
                $("#flowTempOverviewValue").text($(".dropdown#flowTemp .button .selected").text())



                if(hasErrors) return;

                break;
            case 4: //Roof Area
                // moduleFormSaveField("roofArea", $("input[name=roofArea]").val());
                $("#roofAreaOverviewValue").text($("input[name=roofArea]").val() + " m²");

                $.ajax({
                    type: 'POST',
                    url: window.location.href,
                    data: {
                        data: JSON.stringify({
                            action: "moduleForm",
                            module: $(".module-form").data("module"),
                            value: {
                                roofName: $("input[name=roofName]").val(),
                                heatingType: $(".dropdown#heatingType .button .selected").text(),
                                roofArea: $("input[name=roofArea]").val(),
                                roofInsulation: $("#insulationThicknessSlider").val(),
                                roofType: $(".dropdown#roofType .button .selected").text()
                            }
                        })
                    },
                    success: function(data) {
                        //Set current page to one
                        $(".module-form .form-pages").data("current-page", 1);


                        //Reset form fields
                        $("input[name=roofName]").val("");
                        $("input[name=roofArea]").val("");
                        $("#insulationThicknessSlider").val(0);
                        $(".dropdown#roofType .button .selected").text("Select an option");

                        //Set page and breadcrumb back to start
                        $(".module-form .form-progress .stage-button.active").removeClass("active");
                        $(".module-form .form-progress .stage-button").first().addClass("active");
                        $(".module-form .form-pages .page.show").removeClass("show");
                        $(".module-form .form-pages .page").first().addClass("show");

                        //Hide form
                        $(".embed-form").hide();

                        $("#lightsList tbody").append(data);
                        if($("#lightsList tbody tr").length > 0) {
                            $(".listNoData").remove();
                        }
                    }
                });
                return;
        }
    }

    //Commercial Heat Pump Module
    if(moduleForm.data("module") === "commercialHeatPump"){
        let hasErrors = false;

        //Saving
        switch(currentPage){
            case 1: //General
                //System requirements
                if($(".dropdown#systemRequirements .button .selected").text() === "Select an option"){
                    $(".dropdown#systemRequirements").addClass("error");
                    hasErrors = true;
                } else {
                    $(".dropdown#systemRequirements").removeClass("error");
                    moduleFormSaveField("systemRequirements", $(".dropdown#systemRequirements .button .selected").text());

                    switch($(".dropdown#systemRequirements .button .selected").text()){
                        case "Heating & Hot Water":
                            $("#airToAirValueArea").hide();
                            $("#hotWaterValueArea").show();
                            $("#heatingValueArea").show();
                            $("#nonAirToAirValues").show();
                            break;
                        case "Only Heating":
                            $("#airToAirValueArea").hide();
                            $("#heatingValueArea").show();
                            $("#hotWaterValueArea").hide();
                            $("#nonAirToAirValues").show();
                            break;
                        case "Only Hot Water":
                            $("#airToAirValueArea").hide();
                            $("#hotWaterValueArea").show();
                            $("#heatingValueArea").hide();
                            $("#nonAirToAirValues").show();
                            break;
                        case "Air to Air":
                            $("#airToAirValueArea").show();
                            $("#hotWaterValueArea").hide();
                            $("#heatingValueArea").hide();
                            $("#nonAirToAirValues").hide();
                            break;
                    }
                }
                $("#projectSystemRequirementsValue").text($(".dropdown#systemRequirements .button .selected").text())

                if($(".dropdown#systemRequirements .button .selected").text() !== "Air to Air" && $(".dropdown#systemRequirements .button .selected").text() !== "Select an option"){
                    if($("input[name=systemEfficiency]").val() === "") {
                        $("input[name=systemEfficiency]").parents(".input").addClass("error");
                        hasErrors = true;
                    } else {
                        $("input[name=systemEfficiency]").parents(".input").removeClass("error");
                        moduleFormSaveField("systemEfficiency", $("input[name=systemEfficiency]").val());
                    }
                    $("#systemEfficiencyFigureValue").text($("input[name=systemEfficiency]").val() + "%")

                    if($(".dropdown#systemRequirements .button .selected").text() === "Only Heating" || $(".dropdown#systemRequirements .button .selected").text() === "Heating & Hot Water"){
                        if($(".dropdown#flowTemperature .button .selected").text() === "Select an option"){
                            $(".dropdown#flowTemperature").addClass("error");
                            hasErrors = true;
                        } else {
                            $(".dropdown#flowTemperature").removeClass("error");
                            moduleFormSaveField("flowTemperature", $(".dropdown#flowTemperature .button .selected").text().replace('°C',''));
                        }
                        $("#flowTemperatureValue").text($(".dropdown#flowTemperature .button .selected").text())
                    }
                }

                if(hasErrors) return;
                break;
            case 2: //Heating Data
                let calculationMethod = $(".dropdown#calculation-method .button .selected").data("value");

                $("#calculationMethodValue").text(calculationMethod.charAt(0).toUpperCase() + calculationMethod.slice(1));

                //Save calculation method
                moduleFormSaveField("calculationMethod", calculationMethod);

                //Calculations method
                if(calculationMethod === "simple"){
                    $("#heatingValueArea .heatingDetailedCalculationsFields").hide();
                    $("#heatingValueArea .heatingSimpleCalculationsFields").show();

                    /*
                    --------------------------
                    Simple heat pump estimate calculation (using generic heat loss factors)
                    --------------------------
                    */

                    $("#calculation-method").removeClass("error");

                    //Floor area
                    if($("input[name=floorArea]").val() === ""){
                        $("input[name=floorArea]").parents(".input-field").addClass("error");
                        hasErrors = true;
                    } else {
                        $("input[name=floorArea]").parents(".input-field").removeClass("error");
                        moduleFormSaveField("floorArea", $("input[name=floorArea]").val());
                    }
                    $("#floorAreaValue").text($("input[name=floorArea]").val() + " m²")

                    //Do you know the building heat loss?
                    const peakHeatLossOptions = $(".dropdown#peak-heat-loss-options .button .selected").text();
                    if(peakHeatLossOptions === "Select an option") {
                        $("#building-heat-loss-known").addClass("error");
                        hasErrors = true;
                    } else {
                        $("#building-heat-loss-known").removeClass("error");
                        moduleFormSaveField("peakHeatLossOptions", peakHeatLossOptions);
                        $("#peakHeatLossOptionsValue").text(peakHeatLossOptions)

                        if(peakHeatLossOptions === "Yes") {
                            $(".row[data-field=peakHeatLossBuildingTypeValue]").hide();
                            $(".row[data-field=peakHeatLossManualValue]").show();

                            const manualPeakHeatLoss = $("input[name=manualPeakHeatLoss]").val();

                            if(manualPeakHeatLoss === "") {
                                $("input[name=manualPeakHeatLoss]").parents(".input-field").addClass("error");
                                hasErrors = true;
                            } else {
                                $("input[name=manualPeakHeatLoss]").parents(".input-field").removeClass("error");
                                moduleFormSaveField("manualPeakHeatLoss", manualPeakHeatLoss);
                                $("#peakHeatLossManualValue").text(manualPeakHeatLoss + " W/m²")
                            }
                        } else {
                            $(".row[data-field=peakHeatLossBuildingTypeValue]").show();
                            $(".row[data-field=peakHeatLossManualValue]").hide();

                            const peakHeatLossBuildingType = $(".dropdown#peak-heat-loss-building-type .button .selected").text();
                            if(peakHeatLossBuildingType === "Select an option") {
                                $("#peak-heat-loss-building-type").addClass("error");
                                hasErrors = true;
                            } else {
                                $("#peak-heat-loss-building-type").removeClass("error");
                                moduleFormSaveField("peakHeatLossBuildingType", peakHeatLossBuildingType);
                                $("#peakHeatLossBuildingTypeValue").text(peakHeatLossBuildingType)
                            }
                        }
                    }

                } else if (calculationMethod === "detailed"){
                    /*
                    --------------------------
                    Detailed heat pump estimate calculation (using measured heat loss)
                    --------------------------
                    */

                    $("#heatingValueArea .heatingDetailedCalculationsFields").show();
                    $("#heatingValueArea .heatingSimpleCalculationsFields").hide();

                    $("#calculation-method").removeClass("error");


                    //Building heat loss options
                    if($(".dropdown#building-heat-loss-known .button .selected").text() === "Select an option"){
                        $(".dropdown#building-heat-loss-known").addClass("error");
                        hasErrors = true;
                    } else {
                        $(".dropdown#building-heat-loss-known").removeClass("error");
                        moduleFormSaveField("buildingHeatLossKnown", $(".dropdown#building-heat-loss-known .button .selected").text());
                    }
                    $("#buildingHeatLossKnownValue").text($(".dropdown#building-heat-loss-known .button .selected").text())


                    if($(".dropdown#building-heat-loss-known .button .selected").text() === "No"){
                        //Change overview view
                        $(".hotWaterDemandUnknownFields").show();
                        $(".row[data-field='buildingHeatLossValue']").hide();

                        if($(".dropdown#air-changes-options .button .selected").text() === "Select an option"){
                            $(".dropdown#air-changes-options").addClass("error");
                            hasErrors = true;
                        } else {
                            $(".dropdown#air-changes-options").removeClass("error");
                            moduleFormSaveField("airChangesOptions", $(".dropdown#air-changes-options .button .selected").text());

                            if($(".dropdown#air-changes-options .button .selected").text() === "Yes"){
                                $(".row[data-field=airChangesNumberValue]").hide();
                            } else {
                                $(".row[data-field=airChangesNumberValue]").show();
                            }
                        }
                        $("#airChangesOptionsValue").text($(".dropdown#air-changes-options .button .selected").text())


                        if($(".dropdown#air-changes-options .button .selected").text() === "No"){
                            if($(".dropdown#air-changes-number .button .selected").text() === "Select an option"){
                                $(".dropdown#air-changes-number").addClass("error");
                                hasErrors = true;
                            } else {
                                $(".dropdown#air-changes-number").removeClass("error");
                                moduleFormSaveField("airChangesNumber", $(".dropdown#air-changes-number .button .selected").text());
                            }
                        }
                        $("#airChangesNumberValue").text($(".dropdown#air-changes-number .button .selected").text())

                        //Heated Space Length
                        if($("input[name=heatedSpaceLength]").val() === ""){
                            $("input[name=heatedSpaceLength]").parents(".input").addClass("error");
                            hasErrors = true;
                        } else {
                            $("input[name=heatedSpaceLength]").parents(".input").removeClass("error");
                            moduleFormSaveField("heatedSpaceLength", $("input[name=heatedSpaceLength]").val());
                        }
                        $("#heatedSpaceLengthValue").text($("input[name=heatedSpaceLength]").val() + "m")

                        //Heated Space Width
                        if($("input[name=heatedSpaceWidth]").val() === ""){
                            $("input[name=heatedSpaceWidth]").parents(".input").addClass("error");
                            hasErrors = true;
                        } else {
                            $("input[name=heatedSpaceWidth]").parents(".input").removeClass("error");
                            moduleFormSaveField("heatedSpaceWidth", $("input[name=heatedSpaceWidth]").val());
                        }
                        $("#heatedSpaceWidthValue").text($("input[name=heatedSpaceWidth]").val() + "m")


                        //Heated Space Height
                        if($("input[name=heatedSpaceHeight]").val() === ""){
                            $("input[name=heatedSpaceHeight]").parents(".input").addClass("error");
                            hasErrors = true;
                        } else {
                            $("input[name=heatedSpaceHeight]").parents(".input").removeClass("error");
                            moduleFormSaveField("heatedSpaceHeight", $("input[name=heatedSpaceHeight]").val());
                        }
                        $("#heatedSpaceHeightValue").text($("input[name=heatedSpaceHeight]").val() + "m")


                        //Max Design Temperature
                        if($("input[name=maxDesignTemperature]").val() === ""){
                            $("input[name=maxDesignTemperature]").parents(".input").addClass("error");
                            hasErrors = true;
                        } else {
                            $("input[name=maxDesignTemperature]").parents(".input").removeClass("error");
                            moduleFormSaveField("maxDesignTemperature", $("input[name=maxDesignTemperature]").val());
                        }
                        $("#maxDesignTempValue").text($("input[name=maxDesignTemperature]").val() + "°C")

                        //Design Room Temperature
                        if($("input[name=designRoomTemperature]").val() === ""){
                            $("input[name=designRoomTemperature]").parents(".input").addClass("error");
                            hasErrors = true;
                        } else {
                            $("input[name=designRoomTemperature]").parents(".input").removeClass("error");
                            moduleFormSaveField("designRoomTemperature", $("input[name=designRoomTemperature]").val());
                        }
                        $("#designRoomTempValue").text($("input[name=designRoomTemperature]").val() + "°C")


                        //External Elements
                        let externalElementsData = [];

                        //Extract table rows in array
                        $("#externalElementsTable tbody tr").each(function(i, obj) {
                            let columns = $(obj).find("td");
                            let entry = {
                                element: null,
                                uValue: null,
                                length: null,
                                widthHeight: null,
                                number: null
                            };

                            //Map row cells/columns to array values
                            columns.each(function(i, obj){
                                switch(i){
                                    case 0:
                                        entry.element = $(obj).text();
                                        break;
                                    case 1:
                                        entry.uValue = $(obj).text();
                                        break;
                                    case 2:
                                        entry.length = $(obj).text();
                                        break;
                                    case 3:
                                        entry.widthHeight = $(obj).text();
                                        break;
                                    case 4:
                                        entry.number = $(obj).text();
                                        break;
                                    default:
                                        break;
                                }
                            });

                            //Add to array
                            externalElementsData.push(entry);
                        });

                        //Submit list
                        moduleFormSaveField("externalElements", JSON.stringify(externalElementsData));
                    } else {
                        //Change overview view
                        $(".hotWaterDemandUnknownFields").hide();
                        $(".row[data-field='buildingHeatLossValue']").show();

                        //Building Heat Loss
                        if($("input[name=buildingHeatLoss]").val() === ""){
                            $("input[name=buildingHeatLoss]").parents(".input-field").addClass("error");
                            hasErrors = true;
                        } else {
                            $("input[name=buildingHeatLoss]").parents(".input-field").removeClass("error");
                            moduleFormSaveField("buildingHeatLoss", $("input[name=buildingHeatLoss]").val());
                        }
                        $("#buildingHeatLossValue").text($("input[name=buildingHeatLoss]").val() + " W")
                    }
                } else {
                    $("#heatingValueArea .heatingDetailedCalculationsFields").hide();
                    $("#heatingValueArea .heatingSimpleCalculationsFields").hide();

                    $("#calculation-method").addClass("error");
                    hasErrors = true;
                }

                if(hasErrors) return;
                break;
            case 3: //Hot Water Data
                //Hot water demand options
                if($(".dropdown#hotwater-demand-options .button .selected").text() === "Select an option"){
                    $(".dropdown#hotwater-demand-options").addClass("error");
                    hasErrors = true;
                } else {
                    $(".dropdown#hotwater-demand-options").removeClass("error");
                    moduleFormSaveField("hotWaterDemandOptions", $(".dropdown#hotwater-demand-options .button .selected").text());
                }

                if($(".dropdown#hotwater-demand-options .button .selected").text() == "Yes"){
                    $(".row[data-field=hotWaterDemandValue]").show();
                    $("#unknownHotWaterDemandValue").hide();
                } else {
                    $(".row[data-field=hotWaterDemandValue]").hide();
                    $("#unknownHotWaterDemandValue").show();
                }
                $("#hotWaterDemandOptionsValue").text($(".dropdown#hotwater-demand-options .button .selected").text());

                if($(".dropdown#hotwater-demand-options .button .selected").text() === "Yes"){
                    //Annual hot water demand
                    if($("input[name=annualHotWaterDemand]").val() === ""){
                        $("input[name=annualHotWaterDemand]").parent(".input-field").addClass("error");
                        hasErrors = true;
                    } else {
                        $("input[name=annualHotWaterDemand]").parent(".input-field").removeClass("error");
                        moduleFormSaveField("hotWaterDemand", $("input[name=annualHotWaterDemand]").val());
                    }
                    $("#hotWaterDemandValue").text($("input[name=annualHotWaterDemand]").val())


                } else if ($(".dropdown#hotwater-demand-options .button .selected").text() === "No"){
                    if($(".dropdown#hotwater-estimate-litres .button .selected").text() === "Select an option"){
                        $(".dropdown#hotwater-estimate-litres").addClass("error");
                        hasErrors = true;
                    } else {
                        $(".dropdown#hotwater-estimate-litres").removeClass("error");
                        moduleFormSaveField("hotWaterEstimateOptions", $(".dropdown#hotwater-estimate-litres .button .selected").text());

                        if($(".dropdown#hotwater-estimate-litres .button .selected").text() === "Yes"){
                            $("#hotwater-estimate-value").hide();
                            $("#hotwater-demand-value").show();

                            if($("input[name=hotWaterConsumption]").val() === ""){
                                $("input[name=hotWaterConsumption]").parent(".input-field").addClass("error");
                                hasErrors = true;
                            } else {
                                $("input[name=hotWaterConsumption]").parent(".input-field").removeClass("error");
                                moduleFormSaveField("hotWaterConsumption", $("input[name=hotWaterConsumption]").val());
                            }
                            $("#hotWaterConsumptionValue").text($("input[name=hotWaterConsumption]").val() + " litres")

                        } else if ($(".dropdown#hotwater-estimate-litres .button .selected").text() === "No") {
                            $("#hotwater-estimate-value").show();
                            $("#hotwater-demand-value").hide();

                            if ($(".dropdown#hotwater-estimate-fields-building-use .button .selected").text() === "Select an option") {
                                $(".dropdown#hotwater-estimate-fields-building-use").addClass("error");
                                hasErrors = true;
                            } else {
                                $(".dropdown#hotwater-estimate-fields-building-use").removeClass("error");
                                moduleFormSaveField("hotWaterEstimateFieldsBuildingUse", $(".dropdown#hotwater-estimate-fields-building-use .button .selected").text());
                            }
                            $("#hotWaterEstimateFieldsBuildingUseValue").text($(".dropdown#hotwater-estimate-fields-building-use .button .selected").text())

                            if ($("input[name=hotWaterEstimateFieldsOccupants]").val() === "") {
                                $("input[name=hotWaterEstimateFieldsOccupants]").parent(".input-field").addClass("error");
                            } else {
                                $("input[name=hotWaterEstimateFieldsOccupants]").parent(".input-field").removeClass("error");
                                moduleFormSaveField("hotWaterEstimateFieldsOccupants", $("input[name=hotWaterEstimateFieldsOccupants]").val());
                            }
                            $("#hotWaterEstimateFieldsOccupantsValue").text($("input[name=hotWaterEstimateFieldsOccupants]").val())
                        }

                        if($("input[name=hotWaterEstimateFieldsDesiredTemp]").val() === ""){
                            $("input[name=hotWaterEstimateFieldsDesiredTemp]").parent(".input-field").addClass("error");
                            hasErrors = true;
                        } else {
                            $("input[name=hotWaterEstimateFieldsDesiredTemp]").parent(".input-field").removeClass("error");
                            moduleFormSaveField("hotWaterEstimateFieldsDesiredTemp", $("input[name=hotWaterEstimateFieldsDesiredTemp]").val());
                        }
                        $("#hotWaterEstimateFieldsDesiredTempValue").text($("input[name=hotWaterEstimateFieldsDesiredTemp]").val() + "°C")

                        if($("input[name=hotWaterEstimateFieldsIncomingTemp]").val() === ""){
                            $("input[name=hotWaterEstimateFieldsIncomingTemp]").parent(".input-field").addClass("error");
                            hasErrors = true;
                        } else {
                            $("input[name=hotWaterEstimateFieldsIncomingTemp]").parent(".input-field").removeClass("error");
                            moduleFormSaveField("hotWaterEstimateFieldsIncomingTemp", $("input[name=hotWaterEstimateFieldsIncomingTemp]").val());
                        }
                        $("#hotWaterEstimateFieldsIncomingTempValue").text($("input[name=hotWaterEstimateFieldsIncomingTemp]").val() + "°C")

                        if($("input[name=hotWaterEstimateFieldsSystemEfficiency]").val() === ""){
                            $("input[name=hotWaterEstimateFieldsSystemEfficiency]").parent(".input-field").addClass("error");
                            hasErrors = true;
                        } else {
                            $("input[name=hotWaterEstimateFieldsSystemEfficiency]").parent(".input-field").removeClass("error");
                            moduleFormSaveField("hotWaterEstimateFieldsSystemEfficiency", $("input[name=hotWaterEstimateFieldsSystemEfficiency]").val());
                        }
                        $("#hotWaterEstimateFieldsSystemEfficiencyValue").text($("input[name=hotWaterEstimateFieldsSystemEfficiency]").val() + "%")

                        if($("input[name=hotWaterEstimateFieldsCylinderSize]").val() === ""){
                            $("input[name=hotWaterEstimateFieldsCylinderSize]").parent(".input-field").addClass("error");
                            hasErrors = true;
                        } else {
                            $("input[name=hotWaterEstimateFieldsCylinderSize]").parent(".input-field").removeClass("error");
                            moduleFormSaveField("hotWaterEstimateFieldsCylinderSize", $("input[name=hotWaterEstimateFieldsCylinderSize]").val());
                        }
                        $("#hotWaterEstimateFieldsCylinderSizeValue").text($("input[name=hotWaterEstimateFieldsCylinderSize]").val() + " litres")
                    }
                    $("#hotWaterEstimateOptionsValue").text($(".dropdown#hotwater-estimate-litres .button .selected").text())
                }

                if(hasErrors) return;
                break;
            case 4: //Air to Air
                //Remove errors
                $("input[name=coolingSystemToggle], input[name=heatingSystemToggle]").closest(".input-field").removeClass("error");

                //Cooling System Toggle
                const coolingSystemToggleElement = $("input[name=coolingSystemToggle]");
                const coolingSystemToggle = coolingSystemToggleElement.is(":checked");

                if(coolingSystemToggle){
                    //Efficiency of the existing cooling system
                    const existingCoolingSystemEfficiencyElement = $("input[name=existingCoolingSystemEfficiency]");
                    const existingCoolingSystemEfficiency = parseInt(existingCoolingSystemEfficiencyElement.val());

                    if(isNaN(existingCoolingSystemEfficiency)|| existingCoolingSystemEfficiency < 0){
                        //Add error
                        existingCoolingSystemEfficiencyElement.parent(".input-field").addClass("error");
                        hasErrors = true;
                    } else {
                        //Remove any errors
                        existingCoolingSystemEfficiencyElement.parent(".input-field").removeClass("error");

                        //Save weeks occupied per year
                        moduleFormSaveField("existingCoolingSystemEfficiency", existingCoolingSystemEfficiency);
                        $("#existingCoolingSystemEfficiencyValue").text(existingCoolingSystemEfficiency + "%")
                    }

                    //Annual cooling hours required
                    const annualCoolingHoursElement = $("input[name=annualCoolingHours]");
                    const annualCoolingHours = parseInt(annualCoolingHoursElement.val());

                    if(isNaN(annualCoolingHours)|| annualCoolingHours < 0){
                        //Add error
                        annualCoolingHoursElement.parent(".input-field").addClass("error");
                        hasErrors = true;
                    } else {
                        //Remove any errors
                        annualCoolingHoursElement.parent(".input-field").removeClass("error");

                        //Save weeks occupied per year
                        moduleFormSaveField("annualCoolingHours", annualCoolingHours);
                        $("#annualCoolingHoursValue").text(annualCoolingHours + " hours/year")
                    }

                    //Show values
                    $("#coolingSystemValues").show();
                } else {
                    //Hide values
                    $("#coolingSystemValues").hide();
                }

                //Heating System Toggle
                const heatingSystemToggleElement = $("input[name=heatingSystemToggle]");
                const heatingSystemToggle = heatingSystemToggleElement.is(":checked");

                if(heatingSystemToggle){
                    //Efficiency of the existing heating system
                    const existingHeatingSystemEfficiencyElement = $("input[name=existingHeatingSystemEfficiency]");
                    const existingHeatingSystemEfficiency = parseInt(existingHeatingSystemEfficiencyElement.val());

                    if(isNaN(existingHeatingSystemEfficiency)|| existingHeatingSystemEfficiency < 0){
                        //Add error
                        existingHeatingSystemEfficiencyElement.parent(".input-field").addClass("error");
                        hasErrors = true;
                    } else {
                        //Remove any errors
                        existingHeatingSystemEfficiencyElement.parent(".input-field").removeClass("error");

                        //Save weeks occupied per year
                        moduleFormSaveField("existingHeatingSystemEfficiency", existingHeatingSystemEfficiency);
                        $("#existingCHeatingSystemEfficiencyValue").text(existingHeatingSystemEfficiency + "%")
                    }

                    //Annual heating hours required
                    const annualHeatingHoursElement = $("input[name=annualHeatingHours]");
                    const annualHeatingHours = parseInt(annualHeatingHoursElement.val());

                    if(isNaN(annualHeatingHours)|| annualHeatingHours < 0){
                        //Add error
                        annualHeatingHoursElement.parent(".input-field").addClass("error");
                        hasErrors = true;
                    } else {
                        //Remove any errors
                        annualHeatingHoursElement.parent(".input-field").removeClass("error");

                        //Save weeks occupied per year
                        moduleFormSaveField("annualHeatingHours", annualHeatingHours);
                        $("#annualHeatingHoursValue").text(annualHeatingHours + " hours/year")
                    }

                    //Fossil fuel use
                    const fossilFuelElement = $("input[name=heatingUseFossilFuels]");
                    const fossilFuel = fossilFuelElement.is(":checked")

                    //Save fossil fuel use
                    moduleFormSaveField("heatingUseFossilFuels", fossilFuel);
                    if(fossilFuel){
                        $("#heatingFossilFuelsValue").text("True");
                    } else {
                        $("#heatingFossilFuelsValue").text("False");
                    }

                    //Show values
                    $("#heatingSystemValues").show();
                } else {
                    //Hide values
                    $("#heatingSystemValues").hide();
                }

                if(!coolingSystemToggle && !heatingSystemToggle){
                    //Add error
                    $("input[name=coolingSystemToggle], input[name=heatingSystemToggle]").closest(".field").addClass("error");
                    hasErrors = true;
                } else {
                    //Save Cooling System Toggle
                    moduleFormSaveField("coolingSystemToggle", coolingSystemToggle);

                    //Save Heating System Toggle
                    moduleFormSaveField("heatingSystemToggle", heatingSystemToggle);
                }

                //Get all wired units
                const rooms = $(".form-pages .page[data-page=4] .inputted-units .unit-card");

                //Set value
                $("#roomsValue").text(rooms.length);

                //Show error if no wired units
                if(rooms.length === 0){
                    //Add error
                    $(".form-pages .page[data-page=4] .inputted-units .empty-state").addClass("error");
                    hasErrors = true;
                } else {
                    //Remove any errors
                    $(".form-pages .page[data-page=4] .inputted-units .empty-state").removeClass("error");
                }
                break;
            case 5: //Design Margin
                if($("input[name=systemDesignMargin]").val() === "") {
                    $("input[name=systemDesignMargin]").parents(".input").addClass("error");
                    hasErrors = true;
                } else {
                    $("input[name=systemDesignMargin]").parents(".input").removeClass("error");
                    moduleFormSaveField("designMargin", $("input[name=systemDesignMargin]").val());
                }
                $("#systemDesignMarginFigureValue").text($("input[name=systemDesignMargin]").val() + "%")
                break;
        }

        if(hasErrors) return;
    }

    //Energy Monitoring Module
    if(moduleForm.data("module") === "energyMonitoring"){
        let hasErrors = false;

        switch(currentPage){
            case 1:
                //Incoming Supply Points
                if($("input[name=incomingSupplyPoints]").val() === "" || $("input[name=incomingSupplyPoints]").val() < 0){
                    $("input[name=incomingSupplyPoints]").parent(".input-field").addClass("error");
                    hasErrors = true;
                } else {
                    $("input[name=incomingSupplyPoints]").parent(".input-field").removeClass("error");
                    moduleFormSaveField("incomingSupplyPoints", $("input[name=incomingSupplyPoints]").val());
                }
                $("#incomingSupplyPointsValue").text($("input[name=incomingSupplyPoints]").val())
                break;
            case 2:
                //Smart meters
                if($("input[name=smartMeters]").val() === "" || parseInt($("input[name=smartMeters]").val()) < 0 || parseInt($("input[name=smartMeters]").val()) > parseInt($("input[name=incomingSupplyPoints]").val())){
                    $("input[name=smartMeters]").parent(".input-field").addClass("error");
                    hasErrors = true;
                } else {
                    $("input[name=smartMeters]").parent(".input-field").removeClass("error");
                    moduleFormSaveField("smartMeters", $("input[name=smartMeters]").val());
                }
                $("#smartMetersValue").text($("input[name=smartMeters]").val())
                break;
            case 3:
                //Submeters
                if($("input[name=submeters]").val() === "" || $("input[name=submeters]").val() < 0){
                    $("input[name=submeters]").parent(".input-field").addClass("error");
                    hasErrors = true;
                } else {
                    $("input[name=submeters]").parent(".input-field").removeClass("error");
                    moduleFormSaveField("submeters", $("input[name=submeters]").val());
                }
                $("#submetersValue").text($("input[name=submeters]").val())
                break;
        }
        if(hasErrors) return;
    }

    // Smart TRV Module
    if(moduleForm.data("module") === "smartTRV"){
        let hasErrors = false;

        switch(currentPage){
            // Subscription
            case 1:
                // Get radio input value
                const subscription = $("input[name=subscription-type]:checked").val();

                if (typeof subscription === "undefined") {
                    hasErrors = true;
                } else {
                    moduleFormSaveField("subscriptionType", subscription);
                }
                break;
            // Radiators tab
            case 2:
                //Radiators Quantity
                const radiatorsQuantityElement = $("input[name=radiatorsQuantity]");
                const radiatorsQuantity = parseInt(radiatorsQuantityElement.val());


                if(isNaN(radiatorsQuantity)|| radiatorsQuantity < 1 || radiatorsQuantity > 1000){
                    //Add error
                    radiatorsQuantityElement.parent(".input-field").addClass("error");
                    hasErrors = true;
                } else {
                    //Remove any errors
                    radiatorsQuantityElement.parent(".input-field").removeClass("error");

                    //Save Radiators Quantity
                    moduleFormSaveField("radiatorsQuantity", radiatorsQuantity);

                    //Set Radiators Quantity Value on Finished tab
                    $(".page .input-info .row[data-field='radiatorsQuantity'] .row-value").text(radiatorsQuantity);
                }

                //Adaptor Quantity Toggle
                const adaptorQuantityToggle = $("#adaptorQuantityToggle").prop('checked');

                if(adaptorQuantityToggle){
                    //Quantity of Adaptors
                    const adaptorQuantityElement = $("input[name=adaptorQuantity]");
                    const adaptorQuantity = parseInt(adaptorQuantityElement.val());

                    //Save Adaptor Quantity Toggle
                    moduleFormSaveField("adaptorQuantityToggle", adaptorQuantityToggle);

                    //Show Adaptor Quantity Value on Finished tab
                    $(".page .input-info .row[data-field='adaptorQuantity']").show();

                    if(isNaN(adaptorQuantity) || adaptorQuantity < 0 || radiatorsQuantity < adaptorQuantity){
                        //Add error
                        adaptorQuantityElement.parent(".input-field").addClass("error");
                        hasErrors = true;
                    } else {
                        //Remove any errors
                        adaptorQuantityElement.parent(".input-field").removeClass("error");

                        //Save Adaptor Quantity
                        moduleFormSaveField("adaptorQuantity", adaptorQuantity);

                        //Set Adaptor Quantity Value on Finished tab
                        $(".page .input-info .row[data-field='adaptorQuantity'] .row-value").text(adaptorQuantity);
                    }
                } else {
                    //Hide Adaptor Quantity Value on Finished tab
                    $(".page .input-info .row[data-field='adaptorQuantity']").hide();

                    //Save Adaptor Quantity Toggle
                    moduleFormSaveField("adaptorQuantityToggle", adaptorQuantityToggle);
                }
                break;
            // Connection tab
            case 3:
                //Good Wi-Fi signal in every room
                const goodWiFiSignal = $(".dropdown#goodWiFiSignal .button .selected").text();

                if(goodWiFiSignal === "Select an option"){
                    //Add error
                    $(".dropdown#goodWiFiSignal").addClass("error");
                    hasErrors = true;
                } else {
                    //Remove any errors
                    $(".dropdown#goodWiFiSignal").removeClass("error");

                    //Save Good Wi-Fi Signal
                    moduleFormSaveField("goodWiFiSignal", goodWiFiSignal);

                    //Set Good Wi-Fi Signal Value on Finished tab
                    $(".page .input-info .row[data-field='goodWiFiSignal'] .row-value").text(goodWiFiSignal);

                    if(goodWiFiSignal === "No"){
                        //SIM card quantity
                        let simCardQuantityElement = $("input[name=simCardQuantity]");
                        const simCardQuantity = parseInt(simCardQuantityElement.val());

                        if(isNaN(simCardQuantity) || simCardQuantity < 0 || $("input[name=radiatorsQuantity]").val() < simCardQuantity){
                            //Add error
                            simCardQuantityElement.parent(".input-field").addClass("error");
                            hasErrors = true;
                        } else {
                            //Remove any errors
                            simCardQuantityElement.parent(".input-field").removeClass("error");

                            //Save SIM card quantity
                            moduleFormSaveField("simCardQuantity", simCardQuantity);

                            //Set SIM Card Quantity Value on Finished tab
                            $(".page .input-info .row[data-field='simCardQuantity'] .row-value").text(simCardQuantity);

                            //Show SIM Card Quantity Value on Finished tab
                            $(".page .input-info .row[data-field='simCardQuantity']").show();

                            //Hide Share of Radiators needing SIMs Value on Finished tab
                            $(".page .input-info .row[data-field='shareOfRadsNeedingSims']").hide();
                        }
                    } else if(goodWiFiSignal === "Unknown") {
                        //Assume good Wi-Fi signal
                        const assumeGoodWifiSignal = $(".dropdown#assumeGoodWifiSignal .button .selected").text();

                        if(assumeGoodWifiSignal === "Select an option"){
                            //Add error
                            $(".dropdown#assumeGoodWifiSignal").addClass("error");
                            hasErrors = true;
                        } else {
                            //Remove any errors
                            $(".dropdown#assumeGoodWifiSignal").removeClass("error");

                            //Save Assume Good Wi-Fi Signal
                            moduleFormSaveField("assumeGoodWifiSignal", assumeGoodWifiSignal);

                            //Set Assume Good Wi-Fi Signal Value on Finished tab
                            $(".page .input-info .row[data-field='assumeGoodWifiSignal'] .row-value").text(assumeGoodWifiSignal);

                            //Show Assume Good Wi-Fi Signal Value on Finished tab
                            $(".page .input-info .row[data-field='assumeGoodWifiSignal']").show();

                            //Hide SIM Card Quantity Value on Finished tab
                            $(".page .input-info .row[data-field='simCardQuantity']").hide();

                            if(assumeGoodWifiSignal === "No"){
                                //Share of Radiators needing SIMs
                                const shareOfRadsNeedingSims = $("input#shareOfRadsNeedingSims").val();

                                //Save Share of Radiators needing SIMs Signal
                                moduleFormSaveField("shareOfRadsNeedingSims", shareOfRadsNeedingSims);

                                //Set Share of Radiators needing SIMs Value on Finished tab
                                $(".page .input-info .row[data-field='shareOfRadsNeedingSims'] .row-value").text(shareOfRadsNeedingSims + "%" );

                                //Show Share of Radiators needing SIMs Value on Finished tab
                                $(".page .input-info .row[data-field='shareOfRadsNeedingSims']").show();
                            } else {
                                //Hide Share of Radiators needing SIMs Value on Finished tab
                                $(".page .input-info .row[data-field='shareOfRadsNeedingSims']").hide();
                            }
                        }
                    } else {
                        //Hide Assume Good Wi-Fi Signal Value on Finished tab
                        $(".page .input-info .row[data-field='assumeGoodWifiSignal']").hide();

                        //Hide SIM Card Quantity Value on Finished tab
                        $(".page .input-info .row[data-field='simCardQuantity']").hide();

                        //Hide Share of Radiators needing SIMs Value on Finished tab
                        $(".page .input-info .row[data-field='shareOfRadsNeedingSims']").hide();
                    }
                }


                //Set value on page
                $("#flowTemperatureValue").text(goodWiFiSignal)
                break;
        }
        if(hasErrors) return;
    }

    //Smart AC Control Module
    if(moduleForm.data("module") === "smartACControl"){
        let hasErrors = false;

        switch(currentPage){
            //General tab
            case 1:
                //Usage
                const usageInputs = $("#ac-usage-input-table .time-inputs input");

                //Loop each input
                usageInputs.each(function(){
                    //Check to see if input is empty
                    if($(this).val() === ""){
                        //Add error
                        $(this).parent(".input-field").addClass("error");
                        hasErrors = true;
                    } else {
                        //Get input value
                        const inputValue = $(this).val();

                        //Get input name
                        const inputName = $(this).attr("name");

                        //Save input value
                        moduleFormSaveField(inputName, inputValue);
                    }
                })


                //Weeks occupied per year
                const weeksBuildingOccupiedElement = $("input[name=weeksBuildingOccupied]");
                const weeksBuildingOccupied = parseInt(weeksBuildingOccupiedElement.val());

                if(isNaN(weeksBuildingOccupied)|| weeksBuildingOccupied < 1 || weeksBuildingOccupied > 52){
                    //Add error
                    weeksBuildingOccupiedElement.parent(".input-field").addClass("error");
                    hasErrors = true;
                } else {
                    //Remove any errors
                    weeksBuildingOccupiedElement.parent(".input-field").removeClass("error");

                    //Save weeks occupied per year
                    moduleFormSaveField("weeksBuildingOccupied", weeksBuildingOccupied);
                }

                //System control
                const systemControlElement = $("input[name=systemControl]:checked");
                const systemControl = systemControlElement.val();

                if(typeof systemControl === "undefined"){
                    //Add error
                    $("input[name=systemControl]").closest(".input-field").addClass("error");
                    hasErrors = true;
                } else {
                    //Remove any errors
                    systemControlElement.closest(".input-field").removeClass("error");

                    //Save System control
                    moduleFormSaveField("systemControl", systemControl);
                }

                //Prefill
                if(!hasErrors){
                    //Get inputs in the same row of the table
                    const hours = $("#ac-usage-input-table .time-inputs .hours");

                    //Add up the total time
                    let totalHours = 0;
                    let totalMinutes = 0;
                    hours.each(function(){
                        //Convert string time to hours
                        const time = $(this).text().split(":");
                        let hours = parseInt(time[0]);
                        let minutes = parseInt(time[1]);

                        if($(this).closest(".time-inputs").find(".day-label").text() === "Monday - Friday"){
                            hours = parseInt(time[0]) * 5;
                            minutes = parseInt(time[1]) * 5;
                        }

                        totalHours += hours;
                        totalMinutes += minutes;
                    });

                    //Convert date to decimal hours
                    const decimalHours = totalHours + (totalMinutes / 60);

                    //Update input
                    $("input#ac-usage-hours-decimal").val(decimalHours);

                    //Update hours unoccupied
                    const hoursUnoccupied = 168 - decimalHours;

                    let wiredUnitHoursUnoccupied = $(".module-form[data-module=smartACControl] .form-pages input[name=wiredUnitHoursUnoccupied]")
                    if(wiredUnitHoursUnoccupied.val() === ""){
                        wiredUnitHoursUnoccupied.val(hoursUnoccupied);
                    }

                    let remoteUnitHoursUnoccupied = $(".module-form[data-module=smartACControl] .form-pages input[name=remoteUnitHoursUnoccupied]")
                    if(remoteUnitHoursUnoccupied.val() === ""){
                        remoteUnitHoursUnoccupied.val(hoursUnoccupied);
                    }
                }
                break;
            //Wired Units tab
            case 2:
                //Hours unoccupied
                const wiredUnitHoursUnoccupiedElement = $("input[name=wiredUnitHoursUnoccupied]");
                const wiredUnitHoursUnoccupied = parseInt(wiredUnitHoursUnoccupiedElement.val());

                if(isNaN(wiredUnitHoursUnoccupied)|| wiredUnitHoursUnoccupied < 0 || wiredUnitHoursUnoccupied > 168){
                    //Add error
                    wiredUnitHoursUnoccupiedElement.parent(".input-field").addClass("error");
                    hasErrors = true;
                } else {
                    //Remove any errors
                    wiredUnitHoursUnoccupiedElement.parent(".input-field").removeClass("error");

                    //Save weeks occupied per year
                    moduleFormSaveField("wiredUnitHoursUnoccupied", wiredUnitHoursUnoccupied);
                }

                //Estimated nights AC left on
                const wiredUnitEstimatedNightsACLeftOnElement = $("input[name=wiredUnitEstimatedNightsACLeftOn]");
                const wiredUnitEstimatedNightsACLeftOn = parseInt(wiredUnitEstimatedNightsACLeftOnElement.val());

                if(isNaN(wiredUnitEstimatedNightsACLeftOn)|| wiredUnitEstimatedNightsACLeftOn < 0 || wiredUnitEstimatedNightsACLeftOn > 7){
                    //Add error
                    wiredUnitEstimatedNightsACLeftOnElement.parent(".input-field").addClass("error");
                    hasErrors = true;
                } else {
                    //Remove any errors
                    wiredUnitEstimatedNightsACLeftOnElement.parent(".input-field").removeClass("error");

                    //Save weeks occupied per year
                    moduleFormSaveField("wiredUnitEstimatedNightsACLeftOn", wiredUnitEstimatedNightsACLeftOn);
                }

                //Estimated hours left on
                const wiredUnitEstimatedHoursOnElement = $("input[name=wiredUnitEstimatedHoursOn]");
                const wiredUnitEstimatedHoursOn = parseInt(wiredUnitEstimatedHoursOnElement.val());

                if(isNaN(wiredUnitEstimatedHoursOn)|| wiredUnitEstimatedHoursOn < 0 || wiredUnitEstimatedHoursOn > 168){
                    //Add error
                    wiredUnitEstimatedHoursOnElement.parent(".input-field").addClass("error");
                    hasErrors = true;
                } else {
                    //Remove any errors
                    wiredUnitEstimatedHoursOnElement.parent(".input-field").removeClass("error");

                    //Save weeks occupied per year
                    moduleFormSaveField("wiredUnitEstimatedHoursOn", wiredUnitEstimatedHoursOn);
                }

                //Get all wired units
                const wiredUnits = $(".form-pages .page[data-page=2] .inputted-units .unit-card");

                //Show error if no wired units
                if(wiredUnits.length === 0){
                    //Add error
                    $(".form-pages .page[data-page=2] .inputted-units .empty-state").addClass("error");
                    hasErrors = true;
                } else {
                    //Remove any errors
                    $(".form-pages .page[data-page=2] .inputted-units .empty-state").removeClass("error");
                }
                break;
            //Remote Units tab
            case 3:
                //Hours unoccupied
                const remoteUnitHoursUnoccupiedElement = $("input[name=remoteUnitHoursUnoccupied]");
                const remoteUnitHoursUnoccupied = parseInt(remoteUnitHoursUnoccupiedElement.val());

                if(isNaN(remoteUnitHoursUnoccupied)|| remoteUnitHoursUnoccupied < 0 || remoteUnitHoursUnoccupied > 168){
                    //Add error
                    remoteUnitHoursUnoccupiedElement.parent(".input-field").addClass("error");
                    hasErrors = true;
                } else {
                    //Remove any errors
                    remoteUnitHoursUnoccupiedElement.parent(".input-field").removeClass("error");

                    //Save weeks occupied per year
                    moduleFormSaveField("remoteUnitHoursUnoccupied", remoteUnitHoursUnoccupied);
                }

                //Estimated nights AC left on
                const remoteUnitEstimatedNightsACLeftOnElement = $("input[name=remoteUnitEstimatedNightsACLeftOn]");
                const remoteUnitEstimatedNightsACLeftOn = parseInt(remoteUnitEstimatedNightsACLeftOnElement.val());

                if(isNaN(remoteUnitEstimatedNightsACLeftOn)|| remoteUnitEstimatedNightsACLeftOn < 0 || remoteUnitEstimatedNightsACLeftOn > 7){
                    //Add error
                    remoteUnitEstimatedNightsACLeftOnElement.parent(".input-field").addClass("error");
                    hasErrors = true;
                } else {
                    //Remove any errors
                    remoteUnitEstimatedNightsACLeftOnElement.parent(".input-field").removeClass("error");

                    //Save weeks occupied per year
                    moduleFormSaveField("remoteUnitEstimatedNightsACLeftOn", remoteUnitEstimatedNightsACLeftOn);
                }

                //Estimated hours left on
                const remoteUnitEstimatedHoursOnElement = $("input[name=remoteUnitEstimatedHoursOn]");
                const remoteUnitEstimatedHoursOn = parseInt(remoteUnitEstimatedHoursOnElement.val());

                if(isNaN(remoteUnitEstimatedHoursOn)|| remoteUnitEstimatedHoursOn < 0 || remoteUnitEstimatedHoursOn > 168){
                    //Add error
                    remoteUnitEstimatedHoursOnElement.parent(".input-field").addClass("error");
                    hasErrors = true;
                } else {
                    //Remove any errors
                    remoteUnitEstimatedHoursOnElement.parent(".input-field").removeClass("error");

                    //Save weeks occupied per year
                    moduleFormSaveField("remoteUnitEstimatedHoursOn", remoteUnitEstimatedHoursOn);
                }

                //Get all wired units
                const remoteUnits = $(".form-pages .page[data-page=3] .inputted-units .unit-card");

                //Show error if no wired units
                if(remoteUnits.length === 0){
                    //Add error
                    $(".form-pages .page[data-page=3] .inputted-units .empty-state").addClass("error");
                    hasErrors = true;
                } else {
                    //Remove any errors
                    $(".form-pages .page[data-page=3] .inputted-units .empty-state").removeClass("error");
                }
                break;
        }
        if(hasErrors) return;
    }

    //Voltage Optimisation Module
    if(moduleForm.data("module") === "voltageOptimisation"){
        let hasErrors = false;

        switch(currentPage){
            //Property tab
            case 1:
                //Peak power and annual consumption options
                const peakAndConsumptionOptionElement = $("input[name=peakAndConsumptionOption]:checked");
                const peakAndConsumptionOption = peakAndConsumptionOptionElement.val();

                if(typeof peakAndConsumptionOption === "undefined"){
                    //Add error
                    $("input[name=peakAndConsumptionOption]").closest(".input-field").addClass("error");
                    hasErrors = true;
                } else {
                    //Remove any errors
                    peakAndConsumptionOptionElement.closest(".input-field").removeClass("error");

                    //Save peak and consumption option
                    moduleFormSaveField("peakAndConsumptionOption", peakAndConsumptionOption);

                    switch(peakAndConsumptionOption){
                        case "yes":
                            //Peak power
                            const peakPowerElement = $("input[name=peakPower]");
                            const peakPower = parseInt(peakPowerElement.val());

                            if(isNaN(peakPower)|| peakPower < 1 || peakPower > 10000000){
                                //Add error
                                peakPowerElement.parent(".input-field").addClass("error");
                                hasErrors = true;
                            } else {
                                //Remove any errors
                                peakPowerElement.parent(".input-field").removeClass("error");

                                //Save Radiators Quantity
                                moduleFormSaveField("peakPower", peakPower);

                                //Set Peak Power on Finished tab
                                $(".page .input-info .row[data-field='peakPower'] .row-value").text(peakPower.toLocaleString("en-US") + " kW");
                            }

                            //Annual consumption
                            const annualElectricityConsumptionElement = $("input[name=annualElectricityConsumption]");
                            const annualElectricityConsumption = parseInt(annualElectricityConsumptionElement.val());

                            if(isNaN(annualElectricityConsumption)|| annualElectricityConsumption < 1 || annualElectricityConsumption > 10000000){
                                //Add error
                                annualElectricityConsumptionElement.parent(".input-field").addClass("error");
                                hasErrors = true;
                            } else {
                                //Remove any errors
                                annualElectricityConsumptionElement.parent(".input-field").removeClass("error");

                                //Save Radiators Quantity
                                moduleFormSaveField("annualConsumption", annualElectricityConsumption);

                                //Set Annual Electricity Consumption on the Finished tab
                                $(".page .input-info .row[data-field='annualElectricityConsumption'] .row-value").text(annualElectricityConsumption.toLocaleString("en-US") + " kWh");
                            }

                            //Show peak power and consumption fields
                            $("#peakPowerAndConsumptionFields").show();

                            //Hide email field
                            $("#emailAddressField").hide();
                            break;
                        case "no":
                            if($(".spreadsheet-input-field").hasClass("show")){
                                //Add error class
                                $("#voltageOptimisationSpreadsheetUpload .filepond--root").addClass("error");

                                hasErrors = true;
                            } else {
                                //Remove error class
                                $("#voltageOptimisationSpreadsheetUpload .filepond--root").removeClass("error");

                                //Show peak power and consumption fields
                                $("#peakPowerAndConsumptionFields").show();

                                //Hide email field
                                $("#emailAddressField").hide();

                                const peakPower = parseFloat($("#spreadsheetPeakPowerValue").val());
                                const annualElectricityConsumption = parseFloat($("#spreadsheetAnnualElectricityConsumptionValue").val());

                                //Set Peak Power on Finished tab
                                $(".page .input-info .row[data-field='peakPower'] .row-value").text(peakPower.toLocaleString("en-US") + " kW");

                                //Set Annual Electricity Consumption on the Finished tab
                                $(".page .input-info .row[data-field='annualElectricityConsumption'] .row-value").text(annualElectricityConsumption.toLocaleString("en-US") + " kWh");
                            }
                            break;
                        case "help":
                            //Email address
                            const emailAddressElement = $("input[name=contactEmailAddress]");
                            const emailAddress = emailAddressElement.val();

                            if(emailAddress === "" || !validateEmail(emailAddress)){
                                //Add error
                                emailAddressElement.parent(".input-field").addClass("error");
                                hasErrors = true;
                            } else {
                                //Remove any errors
                                emailAddressElement.parent(".input-field").removeClass("error");

                                //Save Radiators Quantity
                                moduleFormSaveField("contactEmailAddress", emailAddress);

                                //Hide peak power and consumption fields
                                $("#peakPowerAndConsumptionFields").hide();

                                //Show email field
                                $("#emailAddressField").show();

                                //Set Radiators Quantity Value on Finished tab
                                $(".page .input-info .row[data-field='contactEmailAddress'] .row-value").text(emailAddress);
                            }
                            break;
                    }
                }

                //Peak kW and Consumption
                // const peakAndConsumptionElement = $("input[name=peakAndConsumptionOption]:checked");
                // const peakAndConsumption = peakAndConsumptionElement.val();
                //
                // if(typeof peakAndConsumption === "undefined"){
                //     //Add error
                //     $("input[name=peakAndConsumptionOption]").closest(".input-field").addClass("error");
                //     hasErrors = true;
                // } else {
                //     //Remove any errors
                //     $("input[name=peakAndConsumptionOption]").closest(".input-field").removeClass("error");
                //
                //     //Save Radiators Quantity
                //     moduleFormSaveField("peakAndConsumptionOption", peakAndConsumption);
                //
                //     //Set Radiators Quantity Value on Finished tab
                //     // $(".page .input-info .row[data-field='currentPower'] .row-value").text(peakAndConsumption);
                // }


                // //Current power
                // const currentPowerElement = $("input[name=currentPower]");
                // const currentPower = parseInt(currentPowerElement.val());
                //
                // if(isNaN(currentPower)|| currentPower < 1 || currentPower > 1000){
                //     //Add error
                //     currentPowerElement.parent(".input-field").addClass("error");
                //     hasErrors = true;
                // } else {
                //     //Remove any errors
                //     currentPowerElement.parent(".input-field").removeClass("error");
                //
                //     //Save Radiators Quantity
                //     moduleFormSaveField("currentPower", currentPower);
                //
                //     //Set Radiators Quantity Value on Finished tab
                //     $(".page .input-info .row[data-field='currentPower'] .row-value").text(currentPower + " kw");
                // }
                //
                // //Supply voltage
                // const supplyVoltageElement = $("input[name=supplyVoltage]");
                // const supplyVoltage = parseInt(supplyVoltageElement.val());
                //
                // if(isNaN(supplyVoltage)|| supplyVoltage < 1 || supplyVoltage > 1000){
                //     //Add error
                //     supplyVoltageElement.parent(".input-field").addClass("error");
                //     hasErrors = true;
                // } else {
                //     //Remove any errors
                //     supplyVoltageElement.parent(".input-field").removeClass("error");
                //
                //     //Save Radiators Quantity
                //     moduleFormSaveField("supplyVoltage", supplyVoltage);
                //
                //     //Set Radiators Quantity Value on Finished tab
                //     $(".page .input-info .row[data-field='supplyVoltage'] .row-value").text(supplyVoltage + " V");
                // }
                break;
        }
        if(hasErrors) return;
    }

    // Building Fabric Module - Whole Building
    if(moduleForm.data("module") === "buildingFabricWholeBuilding"){
        const success = buildingFabricWholeBuilding(currentPage, nextPageExists, moduleForm);

        if (!success) return;
    }

    // Building Fabric Module - Individual Elements
    if(moduleForm.data("module") === "buildingFabricIndividualElements"){
        const success = buildingFabricIndividualElements(currentPage, nextPageExists, moduleForm);

        if (!success) return;
    }

    //Hot Water Timer Module
    if(moduleForm.data("module") === "hotWaterTimer"){
        let hasErrors = false;

        switch(currentPage){
            // Water Heaters
            case 1:
                // Get plug-in units
                const plugInUnits = $("#plugInHotWaterHeaters").find(".unit-card");

                // Get fused units
                const fusedUnits = $("#fusedHotWaterHeaters").find(".unit-card");

                // Show error if no windows units
                if(plugInUnits.length === 0 && fusedUnits.length === 0){
                    // Add error
                    $("#plugInHotWaterHeaters .empty-state").addClass("error");
                    $("#fusedHotWaterHeaters .empty-state").addClass("error");
                    hasErrors = true;
                } else {
                    // Remove any errors
                    $("#plugInHotWaterHeaters.empty-state").removeClass("error");
                    $("#fusedHotWaterHeaters.empty-state").removeClass("error");
                }
                break;

            // Property Usage
            case 2:
                const annualStandbyHoursElement = $("#annualStandbyHours");
                const annualStandbyHours = parseFloat(annualStandbyHoursElement.val());

                if(isNaN(annualStandbyHours) || typeof annualStandbyHours === "undefined" || annualStandbyHours === ""){
                    //Add error
                    annualStandbyHoursElement.closest(".input-field").addClass("error");
                    hasErrors = true;
                } else {
                    //Remove any errors
                    annualStandbyHoursElement.closest(".input-field").removeClass("error");

                    //Save assumed efficiency of current boiler
                    moduleFormSaveField("annualStandbyHours", annualStandbyHours);
                }

                const additionalStandbyHoursElement = $("#additionalStandbyHours");
                const additionalStandbyHours = parseFloat(additionalStandbyHoursElement.val());

                if(isNaN(additionalStandbyHours) || typeof additionalStandbyHours === "undefined" || additionalStandbyHours === ""){
                    //Add error
                    additionalStandbyHoursElement.closest(".input-field").addClass("error");
                    hasErrors = true;
                } else {
                    //Remove any errors
                    additionalStandbyHoursElement.closest(".input-field").removeClass("error");

                    //Save assumed efficiency of current boiler
                    moduleFormSaveField("additionalStandbyHours", additionalStandbyHours);
                }
                break;
        }
        if(hasErrors) return;
    }

    // EV Charging Stations Module
    if(moduleForm.data("module") === "evChargingStations"){
        let hasErrors = false;

        switch(currentPage){
            // Chargers
            case 1:
                // Get charger units
                const units = $("#evChargingStationUnits").find(".unit-card");

                // Show error if no windows units
                if(units.length === 0){
                    // Add error
                    $("#evChargingStationUnits .empty-state").addClass("error");
                    hasErrors = true;
                } else {
                    // Remove any errors
                    $("#evChargingStationUnits.empty-state").removeClass("error");
                }
                break;

            // Cost
            case 2:
                const totalChargersCostElement = $("#totalChargersCost");
                const totalChargersCost = parseFloat(totalChargersCostElement.val());

                if(isNaN(totalChargersCost) || typeof totalChargersCost === "undefined" || totalChargersCost === ""){
                    // Add error
                    totalChargersCostElement.closest(".input-field").addClass("error");
                    hasErrors = true;
                } else {
                    // Remove any errors
                    totalChargersCostElement.closest(".input-field").removeClass("error");

                    // Save assumed efficiency of current boiler
                    moduleFormSaveField("totalChargersCost", totalChargersCost);
                }

                const totalInstallationCostElement = $("#totalInstallationCost");
                const totalInstallationCost = parseFloat(totalInstallationCostElement.val());

                if(isNaN(totalInstallationCost) || typeof totalInstallationCost === "undefined" || totalInstallationCost === ""){
                    // Add error
                    totalInstallationCostElement.closest(".input-field").addClass("error");
                    hasErrors = true;
                } else {
                    // Remove any errors
                    totalInstallationCostElement.closest(".input-field").removeClass("error");

                    // Save assumed efficiency of current boiler
                    moduleFormSaveField("totalInstallationCost", totalInstallationCost);
                }

                const totalPayterTerminalCostElement = $("#totalPayterTerminalCost");
                const totalPayterTerminalCost = parseFloat(totalPayterTerminalCostElement.val());

                if(isNaN(totalPayterTerminalCost) || typeof totalPayterTerminalCost === "undefined" || totalPayterTerminalCost === ""){
                    // Add error
                    totalPayterTerminalCostElement.closest(".input-field").addClass("error");
                    hasErrors = true;
                } else {
                    // Remove any errors
                    totalPayterTerminalCostElement.closest(".input-field").removeClass("error");

                    // Save assumed efficiency of current boiler
                    moduleFormSaveField("totalPayterTerminalCost", totalPayterTerminalCost);
                }

                const annualBayRentalCostElement = $("#annualBayRentalCost");
                const annualBayRentalCost = parseFloat(annualBayRentalCostElement.val());

                if(isNaN(annualBayRentalCost) || typeof annualBayRentalCost === "undefined" || annualBayRentalCost === ""){
                    // Add error
                    annualBayRentalCostElement.closest(".input-field").addClass("error");
                    hasErrors = true;
                } else {
                    // Remove any errors
                    annualBayRentalCostElement.closest(".input-field").removeClass("error");

                    // Save assumed efficiency of current boiler
                    moduleFormSaveField("annualBayRentalCost", annualBayRentalCost);
                }

                const distributionNetworkOperatorCostsElement = $("#distributionNetworkOperatorCosts");
                const distributionNetworkOperatorCosts = parseFloat(distributionNetworkOperatorCostsElement.val());

                if(isNaN(distributionNetworkOperatorCosts) || typeof distributionNetworkOperatorCosts === "undefined" || distributionNetworkOperatorCosts === ""){
                    // Add error
                    distributionNetworkOperatorCostsElement.closest(".input-field").addClass("error");
                    hasErrors = true;
                } else {
                    // Remove any errors
                    distributionNetworkOperatorCostsElement.closest(".input-field").removeClass("error");

                    // Save assumed efficiency of current boiler
                    moduleFormSaveField("distributionNetworkOperatorCosts", distributionNetworkOperatorCosts);
                }
                break;

            // Energy
            case 3:
                // Energy purchase cost
                const energyPurchaseCostElement = $("#energyPurchaseCost");
                const energyPurchaseCost = parseFloat(energyPurchaseCostElement.val());

                if(isNaN(energyPurchaseCost) || typeof energyPurchaseCost === "undefined" || energyPurchaseCost === ""){
                    // Add error
                    energyPurchaseCostElement.closest(".input-field").addClass("error");
                    hasErrors = true;
                } else {
                    // Remove any errors
                    energyPurchaseCostElement.closest(".input-field").removeClass("error");

                    // Save assumed efficiency of current boiler
                    moduleFormSaveField("energyPurchaseCost", energyPurchaseCost);
                }

                // Annual energy cost increase
                const annualEnergyCostIncreaseElement = $("#annualEnergyCostIncrease");
                const annualEnergyCostIncrease = parseFloat(annualEnergyCostIncreaseElement.val());

                if(isNaN(annualEnergyCostIncrease) || typeof annualEnergyCostIncrease === "undefined" || annualEnergyCostIncrease === ""){
                    // Add error
                    annualEnergyCostIncreaseElement.closest(".input-field").addClass("error");
                    hasErrors = true;
                } else {
                    // Remove any errors
                    annualEnergyCostIncreaseElement.closest(".input-field").removeClass("error");

                    // Save assumed efficiency of current boiler
                    moduleFormSaveField("annualEnergyCostIncrease", annualEnergyCostIncrease);
                }

                // Energy sale price
                const energySalePriceElement = $("#energySalePrice");
                const energySalePrice = parseFloat(energySalePriceElement.val());

                if(isNaN(energySalePrice) || typeof energySalePrice === "undefined" || energySalePrice === ""){
                    // Add error
                    energySalePriceElement.closest(".input-field").addClass("error");
                    hasErrors = true;
                } else {
                    // Remove any errors
                    energySalePriceElement.closest(".input-field").removeClass("error");

                    // Save assumed efficiency of current boiler
                    moduleFormSaveField("energySalePrice", energySalePrice);
                }

                // Annual energy sale increase
                const annualEnergySalePriceIncreaseElement = $("#annualEnergySalePriceIncrease");
                const annualEnergySalePriceIncrease = parseFloat(annualEnergySalePriceIncreaseElement.val());

                if(isNaN(annualEnergySalePriceIncrease) || typeof annualEnergySalePriceIncrease === "undefined" || annualEnergySalePriceIncrease === ""){
                    // Add error
                    annualEnergySalePriceIncreaseElement.closest(".input-field").addClass("error");
                    hasErrors = true;
                } else {
                    // Remove any errors
                    annualEnergySalePriceIncreaseElement.closest(".input-field").removeClass("error");

                    // Save assumed efficiency of current boiler
                    moduleFormSaveField("annualEnergySalePriceIncrease", annualEnergySalePriceIncrease);
                }

                break;
        }
        if(hasErrors) return;
    }

    moduleFormChangePage(pageChange);
}
window.moduleFormInputPageChange = moduleFormInputPageChange;

export function moduleFormSaveField(field, value, checkTechnology = true){
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            data: JSON.stringify({
                action: "moduleForm",
                module: $(".module-form:visible").data("module"),
                field: field,
                value: value
            })
        },
        success: function(data) {
            if(data !== ""){
                console.log(data);
            }

            if(checkTechnology){
                checkTechnologyData();
            }
        }
    });
}
window.moduleFormSaveField = moduleFormSaveField;

export function moduleFormSaveFields(fields, pageNum = 1, callback = null){
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            data: JSON.stringify({
                action: "moduleFormBatch",
                module: $(".module-form").data("module"),
                page: pageNum,
                fields: fields,
            })
        },
        success: function(data) {
            checkTechnologyData();
            //Check to see if a call back has been provided
            if(callback !== null){
                callback(data);
            }
        }
    });
}
window.moduleFormSaveFields = moduleFormSaveFields;

export function centerFormProgressV2ActiveStage() {
    //Get container
    const stageElement = $(".module-form:visible .form-progress-v2 .stages .stage-button.active");
    const stagesContainer = stageElement.closest(".stages");

    //Get widths
    const stageElementWidth = stageElement.width();
    const visibleStagesContainerWidth = stagesContainer.width();

    //Get page number
    const pageNumber = stageElement.data("page");

    //Get total number of pages
    const totalPages = stagesContainer.find(".stage-button").length;

    //Make sure there are stages off-screen
    if((stageElement.width() * totalPages) > visibleStagesContainerWidth){
        let scrollAmount = (pageNumber - 2.5) * stageElementWidth;

        //Floor scroll amount
        if(scrollAmount < 0){
            scrollAmount = 0;
        }

        //Animated scroll
        stagesContainer.animate({
            scrollLeft: scrollAmount
        }, 500);
    }
}
window.centerFormProgressV2ActiveStage = centerFormProgressV2ActiveStage;


//Valid email check
export function validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
}

