import {setBuildingFabricCalculationType} from "@/js/api/building-fabric";

const body = $("body");

// Show whole building calculations
function showWholeBuildingCalculations(){
    // Elements
    let wholeBuildingForm = $(".whole-building-calculation");
    let individualElementsForm = $(".individual-elements-calculation");

    // Hide the selector
    $("#buildingFabricPanel .calculation-type-selector").addClass("hide");

    // Show whole building form
    wholeBuildingForm.show();

    // Hide the individual form
    individualElementsForm.hide();

    // Update switch value
    $(".calculation-type-selector-switch #simple-calculation-type-switch").prop("checked", true);
    $(".calculation-type-selector-switch #detailed-calculation-type-switch").prop("checked", false);

    // Update session variable
    setBuildingFabricCalculationType("whole-building").then(response => {
        checkTechnologyData();
    }).catch(error => {
        alert("Error saving calculation type");
    });
}

// Show individual element calculations
function showIndividualElementCalculations(){
    // Elements
    let wholeBuildingForm = $(".whole-building-calculation");
    let individualElementsForm = $(".individual-elements-calculation");

    // Hide the selector
    $("#buildingFabricPanel .calculation-type-selector").addClass("hide");

    // Show individual elements form
    individualElementsForm.show();

    // Hide whole building form
    wholeBuildingForm.hide();

    // Update switch value
    $(".calculation-type-selector-switch #simple-calculation-type-switch").prop("checked", false);
    $(".calculation-type-selector-switch #detailed-calculation-type-switch").prop("checked", true);

    // Update session variable
    setBuildingFabricCalculationType("individual-elements").then(response => {
        checkTechnologyData();
    }).catch(error => {
        alert("Error saving calculation type");
    });
}

// Calculation selector
body.on('change', '#buildingFabricPanel .calculation-type-selector .radio-inputs input[type=radio]', function(e) {
    // Enable the next button
    $(".calculation-type-selector .submit-button").prop("disabled", false);
});

// Submit calculation type
body.on('click', '#buildingFabricPanel .calculation-type-selector .submit-button', function(e) {
    // Hide the selector
    $(".calculation-type-selector").addClass("hide");

    // Get the selected value
    let selectedValue = $(".calculation-type-selector .radio-inputs input[type=radio]:checked").val();

    // Show switch
    $(".calculation-type-selector-switch").show();

    switch(selectedValue){
        case "individual-elements":
            // Show individual elements form
            showIndividualElementCalculations();
            break;
        case "whole-building":
            // Show whole building form
            showWholeBuildingCalculations();
            break;
    }
});

// Calculation type switch
body.on('change', '#buildingFabricPanel .calculation-type-selector-switch .switch-inputs input[type=radio]', function(e) {
    // Get the selected value
    let selectedValue = $(this).closest(".switch-inputs").find("input[type=radio]:checked").val();

    switch(selectedValue){
        case "individual-elements":
            // Show individual elements form
            showIndividualElementCalculations();
            break;
        case "whole-building":
            // Show whole building form
            showWholeBuildingCalculations();
            break;
    }

    moduleFormInputPageChange(1);
});

