import LogRocket from 'logrocket';
LogRocket.init('knqlln/whatsthepayback');

import Fuse from 'fuse.js';
window.Fuse = Fuse;

import $ from 'jquery';
import sanitizeHtml from 'sanitize-html';

//Simplebar
import 'simplebar';
import 'simplebar/dist/simplebar.css';

// You will need a ResizeObserver polyfill for browsers that don't support it! (iOS Safari, Edge, ...)
import ResizeObserver from 'resize-observer-polyfill';
window.ResizeObserver = ResizeObserver;

//Setup jQuery
window.jQuery = $;
window.$ = $;

//Setup sanitizeHtml
window.sanitizeHtml = sanitizeHtml;

//Stylesheets
import '../scss/site.scss';

// API
import './api/index.js';
import './api/property.js';
import './api/survey.js';

// Scripts
import './smart-timestamp.js';
import './debug.js';
import './functions.js';
import './form-validation.js';
import './navbar.js';
import './script.js';
import './survey-input.js';
import './account.js';
import './tooltips.js';
import './cookieconsent.js';
import './property-energy-form.js';

// Pages
import './pages/technologies.js';
import './pages/overview.js';
import './pages/details.js';

// Modules
import './modules/commercialheatpump.js';
import './modules/energymonitoring.js';
import './modules/refrigeration.js';
import './modules/lighting/index.js';
import './modules/smarttrv.js';
import './modules/smartccontrol.js';
import './modules/voltageoptimisation.js';
import './modules/building-fabric/index.js';
import './modules/hot-water-timer.js';
import './modules/ev-charging-stations.js';

// UI
import './ui/dropdown.js';
