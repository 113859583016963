let body = $("body");



//Close input form
body.on('click', '.module-form[data-module=buildingFabricWholeBuilding]  .unit-input-container .unit-input-form .cancel-button', function(e) {
    const form = $(this).closest(".unit-input-container").find(".unit-input-form");

    //Slide up
    form.slideUp("slow");

    //Close form
    form.removeClass("open");
});

//Submit unit input form
body.on('submit', '#addBuildingFabricWindowsUnitsForm', function(e) {
    e.preventDefault();

    let form = $(this);
    let moduleForm = form.closest(".module-form[data-module=buildingFabricWholeBuilding]");

    //Hide errors
    form.find(".errors").hide();

    //Disable submit button
    form.find("button[type=submit]").prop("disabled", true);

    //Serialize form data
    const formData = $(this).serializeArray();

    //Add current fabric type
    formData.push({
        name: "windowsUnitCurrentFabricType",
        value: form.find("#windowsUnitCurrentFabricType .selected").data("value")
    });

    //Add upgrade wanted option
    formData.push({
        name: "windowsUnitUpgradeWanted",
        value: form.find("#windowsUnitUpgradeWanted .selected").data("value")
    });

    //Ajax post to add unit
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            data: JSON.stringify({
                action: "addBuildingFabricWindowUnit",
                data: formData
            })
        },
        success: function (data) {
            //Clear form
            form[0].reset();

            //Prefill required fields
            form.find("#windowsUnitCurrentUValue").val(form.find("#windowsUnitCurrentUValue").data("default-value"));
            form.find("#windowsUnitUpgradeUValue").val(form.find("#windowsUnitUpgradeUValue").data("default-value"));

            //Reset dropdowns
            form.find(".wtp-dropdown .selected").text("Select an option").data("value", "");

            //Enable submit button
            form.find("button[type=submit]").prop("disabled", false);

            const formContainer = moduleForm.find(".unit-input-container .unit-input-form");

            //Close form
            formContainer.slideUp("slow");
            formContainer.removeClass("open");

            const unitList = moduleForm.find(`.page[data-page='3'] .inputted-units`);

            //Add unit to list
            unitList.append(`<div class="unit-card"><div class="details"><div class="row"><span class="text subtle">Current: ${sanitizeHtml(data.windowsUnitCurrentFabricType)}</span> <span class="text subtle">Area: ${sanitizeHtml(data.windowsUnitArea)}m<sup>2</sup></span></div><div class="row"><span class="text subtle">Current U-Value: ${sanitizeHtml(data.windowsUnitCurrentUValue)}</span><span class="text subtle">Upgrade U-Value: ${sanitizeHtml(data.windowsUnitUpgradeUValue)}</span> </div> <div class="row"> <span class="text subtle">Upgrade wanted: ${sanitizeHtml(data.windowsUnitUpgradeWanted)}</span> </div> </div> <div class="actions"> <button data-id="${sanitizeHtml(data.uuid)}" class="delete-unit"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></button></div></div>`)

            //Remove empty state if needed
            unitList.find(".empty-state").addClass("hide").removeClass("error");
        },
        error: function (data) {
            //Enable submit button
            form.find("button[type=submit]").prop("disabled", false);

            //Clear errors
            form.find(".errors .errors-list").html("");

            //Loop through errors
            $.each(data.responseJSON, function (key, value) {
                //Add error to list
                form.find(".errors .errors-list").append("<li class=\"error-item\">" + value + "</li>");
            });

            //Show errors
            form.find(".errors").show();
        }
    });
});

//Delete unit
body.on('click', '.module-form[data-module=buildingFabricWholeBuilding] .inputted-units .unit-card .delete-unit', function(e) {
    //Confirm delete
    if(!confirm("Are you sure you want to delete this unit?")){
        return;
    }

    //Get unit card
    const unitCard = $(this).closest(".unit-card");

    //Get unit id
    const unitId = $(this).attr("data-id");

    let page = $(this).closest(".page");

    //Get unit type
    let type = "remote";
    if(page.data("page") === 2){
        type = "wired";
    }

    //Remove unit card
    unitCard.remove();

    //Show empty state if needed
    if(page.find(".inputted-units .unit-card").length === 0){
        page.find(".inputted-units .empty-state").removeClass("hide");
    }

    //Ajax post to delete unit
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            data: JSON.stringify({
                action: "removeBuildingFabricWindowUnit",
                type: type,
                id: unitId
            })
        }
    });
});

body.on('click', '.module-form[data-module=buildingFabricWholeBuilding] .wtp-dropdown .dropdown-content li', function(e) {
    let dropdown = $(this).closest(".wtp-dropdown");

    //Get building age letter
    const ageLetter = $(".wtp-dropdown#yearOfConstruction .selected").data("building-age-letter");

    //Standardise age letter
    let standardisedAgeLetter = ageLetter.trim().toLowerCase();

    //Get data attribute name
    const attributeName =  standardisedAgeLetter + "-age-u-value";

    switch(dropdown.data("dropdown-name")){
        case "windows-units-current-fabric-type":
            let windowUValue = $(this).data("u-value");

            //Update field
            $("#addBuildingFabricWindowsUnitsForm #windowsUnitCurrentUValue").val(windowUValue);
            break;
        case "floor-current-fabric-type":
            //Try and get U-value
            let floorUValue = $(this).data(attributeName);

            //Check to see if U-value exists
            if(floorUValue !== undefined && floorUValue !== null && floorUValue !== ""){
                //Update field
                $("#floorCurrentUValue").val(floorUValue);
            }
            break;
        case "walls-current-fabric-type":
            //Try and get U-value
            let wallsUValue = $(this).data(attributeName);

            //Check to see if U-value exists
            if(wallsUValue !== undefined && wallsUValue !== null && wallsUValue !== ""){
                //Update field
                $("#wallsCurrentUValue").val(wallsUValue);
            }
            break;
        case "roof-current-fabric-type":
            //Try and get U-value
            let roofUValue = $(this).data(attributeName);

            //Check to see if U-value exists
            if(roofUValue !== undefined && roofUValue !== null && roofUValue !== ""){
                //Update field
                $("#roofCurrentUValue").val(roofUValue);
            }
            break;
    }
});

body.on('click', '.module-form[data-module=buildingFabricWholeBuilding] .wtp-dropdown#yearOfConstruction .dropdown-content li', function(e) {
    let dropdown = $(this).closest(".wtp-dropdown");

    //Get age letter
    let ageLetter = $(this).data("building-age-letter");

    //Update field
    dropdown.find(".selected").data("building-age-letter", ageLetter);
});

