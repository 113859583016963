import {apiRequest} from "./index";

export function setBuildingFabricCalculationType(type){
    return new Promise(function(resolve, reject){
        apiRequest('buildingFabric', 'setCalculationType', {
            type: type,
        }).then(function(response) {
            resolve(response);
        }).catch(function(error) {
            alert(error?.message || error?.responseText);
            reject(error);
        })
    });
}

export function addIndividualFloorUnit(currentFabricType, area, upgradeWanted, upgradeType, currentUValue, upgradeUValue){
    return new Promise(function(resolve, reject){
        apiRequest('buildingFabric', 'addIndividualFloorUnit', {
            currentFabricType: currentFabricType,
            area: area,
            upgradeWanted: upgradeWanted,
            upgradeType: upgradeType,
            currentUValue: currentUValue,
            upgradeUValue: upgradeUValue,
        }).then(function(response) {
            resolve(response);
        }).catch(function(error) {
            alert(error?.message || error?.responseText);
            reject(error);
        })
    });
}

export function addIndividualWindowUnit(currentFabricType, area, upgradeWanted, upgradeType, currentUValue, upgradeUValue){
    return new Promise(function(resolve, reject){
        apiRequest('buildingFabric', 'addIndividualWindowUnit', {
            currentFabricType: currentFabricType,
            area: area,
            upgradeWanted: upgradeWanted,
            upgradeType: upgradeType,
            currentUValue: currentUValue,
            upgradeUValue: upgradeUValue,
        }).then(function(response) {
            resolve(response);
        }).catch(function(error) {
            alert(error?.message || error?.responseText);
            reject(error);
        })
    });
}

export function addIndividualWallUnit(currentFabricType, area, upgradeWanted, upgradeType, currentUValue, upgradeUValue){
    return new Promise(function(resolve, reject){
        apiRequest('buildingFabric', 'addIndividualWallUnit', {
            currentFabricType: currentFabricType,
            area: area,
            upgradeWanted: upgradeWanted,
            upgradeType: upgradeType,
            currentUValue: currentUValue,
            upgradeUValue: upgradeUValue,
        }).then(function(response) {
            resolve(response);
        }).catch(function(error) {
            alert(error?.message || error?.responseText);
            reject(error);
        })
    });
}

export function addIndividualRoofUnit(currentFabricType, area, upgradeWanted, upgradeType, currentUValue, upgradeUValue){
    return new Promise(function(resolve, reject){
        apiRequest('buildingFabric', 'addIndividualRoofUnit', {
            currentFabricType: currentFabricType,
            area: area,
            upgradeWanted: upgradeWanted,
            upgradeType: upgradeType,
            currentUValue: currentUValue,
            upgradeUValue: upgradeUValue,
        }).then(function(response) {
            resolve(response);
        }).catch(function(error) {
            alert(error?.message || error?.responseText);
            reject(error);
        })
    });
}

export function deleteIndividualRoofUnit(uuid){
    return new Promise(function(resolve, reject){
        apiRequest('buildingFabric', 'deleteIndividualRoofUnit', {
            uuid: uuid,
        }).then(function(response) {
            resolve(response);
        }).catch(function(error) {
            alert(error?.message || error?.responseText);
            reject(error);
        })
    });
}

export function deleteIndividualFloorUnit(uuid){
    return new Promise(function(resolve, reject){
        apiRequest('buildingFabric', 'deleteIndividualFloorUnit', {
            uuid: uuid,
        }).then(function(response) {
            resolve(response);
        }).catch(function(error) {
            alert(error?.message || error?.responseText);
            reject(error);
        })
    });
}

export function deleteIndividualWallUnit(uuid){
    return new Promise(function(resolve, reject){
        apiRequest('buildingFabric', 'deleteIndividualWallUnit', {
            uuid: uuid,
        }).then(function(response) {
            resolve(response);
        }).catch(function(error) {
            alert(error?.message || error?.responseText);
            reject(error);
        })
    });
}

export function deleteIndividualWindowUnit(uuid){
    return new Promise(function(resolve, reject){
        apiRequest('buildingFabric', 'deleteIndividualWindowUnit', {
            uuid: uuid,
        }).then(function(response) {
            resolve(response);
        }).catch(function(error) {
            alert(error?.message || error?.responseText);
            reject(error);
        })
    });
}

export function getPostcodeDegreeDays(postCode){
    return new Promise(function(resolve, reject){
        apiRequest('buildingFabric', 'getPostcodeDegreeDays', {
            postcode: postCode,
        }).then(function(response) {
            resolve(response);
        }).catch(function(error) {
            reject(error);
        })
    });
}