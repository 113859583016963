import {moduleFormSaveField} from "@/js/functions";

/**
 * Validate the building fabric whole building module
 * @param currentPage
 * @param nextPageExists
 * @param moduleForm
 * @returns {boolean} - True if no errors, false if errors
 */
function buildingFabricWholeBuilding(currentPage, nextPageExists, moduleForm) {
    let hasErrors = false;

    switch(currentPage){
        //General Tab
        case 1:
            //Year of construction
            const yearOfConstructionElement = $(".wtp-dropdown#yearOfConstruction");
            const yearOfConstruction = parseInt(yearOfConstructionElement.find(".selected").data("value"));

            if(isNaN(yearOfConstruction) || yearOfConstruction === 0){
                //Add error
                yearOfConstructionElement.addClass("error");
                hasErrors = true;
            } else {
                //Remove any errors
                yearOfConstructionElement.removeClass("error");

                //Save year of construction
                moduleFormSaveField("yearOfConstruction", yearOfConstruction);
            }

            //Number of floors
            const numberOfFloorsElement = $("input[name=numberOfFloors]");
            let numberOfFloors = numberOfFloorsElement.val();

            if(numberOfFloors !== ""){
                numberOfFloors = parseInt(numberOfFloors);
            }

            //Set number of floors (force int)
            numberOfFloorsElement.val(numberOfFloors);

            if(isNaN(numberOfFloors) || typeof numberOfFloors === "undefined" || numberOfFloors === ""){
                //Add error
                numberOfFloorsElement.closest(".input-field").addClass("error");
                hasErrors = true;
            } else {
                //Remove any errors
                numberOfFloorsElement.closest(".input-field").removeClass("error");

                //Save number of floors
                moduleFormSaveField("numberOfFloors", numberOfFloors);
            }

            //Room height
            const roomHeightElement = $("input[name=roomHeight]");
            let roomHeight = roomHeightElement.val();

            if(roomHeight !== ""){
                roomHeight = parseFloat(roomHeight);
            }

            //Set room height (force float)
            roomHeightElement.val(roomHeight);

            if(isNaN(roomHeight) || typeof roomHeight === "undefined" || roomHeight === ""){
                //Add error
                roomHeightElement.closest(".input-field").addClass("error");
                hasErrors = true;
            } else {
                //Remove any errors
                roomHeightElement.closest(".input-field").removeClass("error");

                //Save room height
                moduleFormSaveField("roomHeight", roomHeight);
            }

            //Energy lost through ventilation
            const energyLostThroughVentilationElement = $("input[name=energyLostThroughVentilation]");
            let energyLostThroughVentilation = energyLostThroughVentilationElement.val();

            if(energyLostThroughVentilation !== ""){
                energyLostThroughVentilation = parseFloat(energyLostThroughVentilation);
            }

            //Set energy lost through ventilation (force float)
            energyLostThroughVentilationElement.val(energyLostThroughVentilation);

            if(isNaN(energyLostThroughVentilation) || typeof energyLostThroughVentilation === "undefined" || energyLostThroughVentilation === ""){
                //Add error
                energyLostThroughVentilationElement.closest(".input-field").addClass("error");
                hasErrors = true;
            } else {
                //Remove any errors
                energyLostThroughVentilationElement.closest(".input-field").removeClass("error");

                //Save energy lost through ventilation
                moduleFormSaveField("energyLostThroughVentilation", energyLostThroughVentilation);
            }


            //Assumed efficiency of current boiler
            const assumedEfficiencyOfCurrentBoilerElement = $("input[name=assumedEfficiencyOfCurrentBoiler]");
            let assumedEfficiencyOfCurrentBoiler = assumedEfficiencyOfCurrentBoilerElement.val();

            if(assumedEfficiencyOfCurrentBoiler !== ""){
                assumedEfficiencyOfCurrentBoiler = parseFloat(assumedEfficiencyOfCurrentBoiler);
            }

            //Set energy lost through ventilation (force float)
            assumedEfficiencyOfCurrentBoilerElement.val(assumedEfficiencyOfCurrentBoiler);

            if(isNaN(assumedEfficiencyOfCurrentBoiler) || typeof assumedEfficiencyOfCurrentBoiler === "undefined" || assumedEfficiencyOfCurrentBoiler === ""){
                //Add error
                assumedEfficiencyOfCurrentBoilerElement.closest(".input-field").addClass("error");
                hasErrors = true;
            } else {
                //Remove any errors
                assumedEfficiencyOfCurrentBoilerElement.closest(".input-field").removeClass("error");

                //Save assumed efficiency of current boiler
                moduleFormSaveField("assumedEfficiencyOfCurrentBoiler", assumedEfficiencyOfCurrentBoiler);
            }
            break;

        //Floor Tab
        case 2:
            //#region Current fabric type
            const floorCurrentFabricElement = $(".wtp-dropdown#floorCurrentFabricType");
            const floorCurrentFabric = parseInt(floorCurrentFabricElement.find(".selected").data("value"));

            if(isNaN(floorCurrentFabric) || floorCurrentFabric === 0){
                //Add error
                floorCurrentFabricElement.addClass("error");
                hasErrors = true;
            } else {
                //Remove any errors
                floorCurrentFabricElement.removeClass("error");

                //Save floor current fabric type
                moduleFormSaveField("floorCurrentFabricType", floorCurrentFabric);
            }
            //#endregion

            //#region Upgrade type
            const floorUpgradeTypeElement = $(".wtp-dropdown#floorUpgradeType");
            const floorUpgradeType = parseInt(floorUpgradeTypeElement.find(".selected").data("value"));

            if(isNaN(floorUpgradeType) || floorUpgradeType === 0){
                //Add error
                floorUpgradeTypeElement.addClass("error");
                hasErrors = true;
            } else {
                //Remove any errors
                floorUpgradeTypeElement.removeClass("error");

                //Save floor upgrade type
                moduleFormSaveField("floorUpgradeType", floorUpgradeType);
            }
            //#endregion

            //#region Area
            const floorAreaElement = $("input[name=floorArea]");
            let floorArea = floorAreaElement.val();

            if(floorArea !== ""){
                floorArea = parseFloat(floorArea);
            }

            //Set area (force float)
            floorAreaElement.val(floorArea);

            if(isNaN(floorArea) || typeof floorArea === "undefined" || floorArea === ""){
                //Add error
                floorAreaElement.closest(".input-field").addClass("error");
                hasErrors = true;
            } else {
                //Remove any errors
                floorAreaElement.closest(".input-field").removeClass("error");

                //Save area
                moduleFormSaveField("floorArea", floorArea);
            }
            //#endregion

            //#region Upgrade wanted
            const floorUpgradeWantedElement = $(".wtp-dropdown#floorUpgradeWanted");
            const floorUpgradeWanted = floorUpgradeWantedElement.find(".selected").data("value");

            if(floorUpgradeWanted === 0 || floorUpgradeWanted === ""){
                //Add error
                floorUpgradeWantedElement.addClass("error");
                hasErrors = true;
            } else {
                //Remove any errors
                floorUpgradeWantedElement.removeClass("error");

                //Save floor upgrade type
                moduleFormSaveField("floorUpgradeWanted", floorUpgradeWanted);
            }
            //#endregion

            //#region Current u-value
            const floorCurrentUValueElement = $("input[name=floorCurrentUValue]");
            let floorCurrentUValue = floorCurrentUValueElement.val();

            if(floorCurrentUValue !== ""){
                floorCurrentUValue = parseFloat(floorCurrentUValue);
            }

            //Set floor current u-value (force float)
            floorCurrentUValueElement.val(floorCurrentUValue);

            if(isNaN(floorCurrentUValue) || typeof floorCurrentUValue === "undefined" || floorCurrentUValue === ""){
                //Add error
                floorCurrentUValueElement.closest(".input-field").addClass("error");
                hasErrors = true;
            } else {
                //Remove any errors
                floorCurrentUValueElement.closest(".input-field").removeClass("error");

                //Save current u-value area
                moduleFormSaveField("floorCurrentUValue", floorCurrentUValue);
            }
            //#endregion

            //#region Upgrade u-value
            const floorUpgradeUValueElement = $("input[name=floorUpgradeUValue]");
            let floorUpgradeUValue = floorUpgradeUValueElement.val();

            if(floorUpgradeUValue !== ""){
                floorUpgradeUValue = parseFloat(floorUpgradeUValue);
            }

            //Set floor upgrade u-value (force float)
            floorUpgradeUValueElement.val(floorUpgradeUValue);

            if(isNaN(floorUpgradeUValue) || typeof floorUpgradeUValue === "undefined" || floorUpgradeUValue === ""){
                //Add error
                floorUpgradeUValueElement.closest(".input-field").addClass("error");
                hasErrors = true;
            } else {
                //Remove any errors
                floorUpgradeUValueElement.closest(".input-field").removeClass("error");

                //Save current u-value area
                moduleFormSaveField("floorUpgradeUValue", floorUpgradeUValue);
            }
            //#endregion
            break;

        //Windows Tab
        case 3:
            //Get all windows units
            const windowsUnits = $(".form-pages .page[data-page=3] .inputted-units .unit-card");

            //Show error if no windows units
            if(windowsUnits.length === 0){
                //Add error
                $(".form-pages .page[data-page=3] .inputted-units .empty-state").addClass("error");
                hasErrors = true;
            } else {
                //Remove any errors
                $(".form-pages .page[data-page=3] .inputted-units .empty-state").removeClass("error");
            }
            break;

        //Walls Tab
        case 4:
            //#region Current fabric type
            const wallsCurrentFabricElement = $(".wtp-dropdown#wallsCurrentFabricType");
            const wallsCurrentFabric = parseInt(wallsCurrentFabricElement.find(".selected").data("value"));

            if(isNaN(wallsCurrentFabric) || wallsCurrentFabric === 0){
                //Add error
                wallsCurrentFabricElement.addClass("error");
                hasErrors = true;
            } else {
                //Remove any errors
                wallsCurrentFabricElement.removeClass("error");

                //Save walls current fabric type
                moduleFormSaveField("wallsCurrentFabricType", wallsCurrentFabric);
            }
            //#endregion

            //#region Upgrade type
            const wallsUpgradeTypeElement = $(".wtp-dropdown#wallsUpgradeType");
            const wallsUpgradeType = parseInt(wallsUpgradeTypeElement.find(".selected").data("value"));

            if(isNaN(wallsUpgradeType) || wallsUpgradeType === 0){
                //Add error
                wallsUpgradeTypeElement.addClass("error");
                hasErrors = true;
            } else {
                //Remove any errors
                wallsUpgradeTypeElement.removeClass("error");

                //Save floor upgrade type
                moduleFormSaveField("wallsUpgradeType", wallsUpgradeType);
            }
            //#endregion

            //#region Area
            const wallsAreaElement = $("input[name=wallsArea]");
            let wallsArea = wallsAreaElement.val();

            if(wallsArea !== ""){
                wallsArea = parseFloat(wallsArea);
            }

            //Set area (force float)
            wallsAreaElement.val(wallsArea);

            if(isNaN(wallsArea) || typeof wallsArea === "undefined" || wallsArea === ""){
                //Add error
                wallsAreaElement.closest(".input-field").addClass("error");
                hasErrors = true;
            } else {
                //Remove any errors
                wallsAreaElement.closest(".input-field").removeClass("error");

                //Save area
                moduleFormSaveField("wallsArea", wallsArea);
            }
            //#endregion

            //#region Upgrade wanted
            const wallsUpgradeWantedElement = $(".wtp-dropdown#wallsUpgradeWanted");
            const wallsUpgradeWanted = wallsUpgradeWantedElement.find(".selected").data("value");

            if(wallsUpgradeWanted === 0 ||wallsUpgradeWanted === ""){
                //Add error
                wallsUpgradeWantedElement.addClass("error");
                hasErrors = true;
            } else {
                //Remove any errors
                wallsUpgradeWantedElement.removeClass("error");

                //Save floor upgrade type
                moduleFormSaveField("wallsUpgradeWanted", wallsUpgradeWanted);
            }
            //#endregion

            //#region Current u-value
            const wallsCurrentUValueElement = $("input[name=wallsCurrentUValue]");
            let wallsCurrentUValue = wallsCurrentUValueElement.val();

            if(wallsCurrentUValue !== ""){
                wallsCurrentUValue = parseFloat(wallsCurrentUValue);
            }

            //Set walls current u-value (force float)
            wallsCurrentUValueElement.val(wallsCurrentUValue);

            if(isNaN(wallsCurrentUValue) || typeof wallsCurrentUValue === "undefined" || wallsCurrentUValue === ""){
                //Add error
                wallsCurrentUValueElement.closest(".input-field").addClass("error");
                hasErrors = true;
            } else {
                //Remove any errors
                wallsCurrentUValueElement.closest(".input-field").removeClass("error");

                //Save current u-value area
                moduleFormSaveField("wallsCurrentUValue", wallsCurrentUValue);
            }
            //#endregion

            //#region Upgrade u-value
            const wallsUpgradeUValueElement = $("input[name=wallsUpgradeUValue]");
            let wallsUpgradeUValue =wallsUpgradeUValueElement.val();

            if(wallsUpgradeUValue !== ""){
                wallsUpgradeUValue = parseFloat(wallsUpgradeUValue);
            }

            //Set walls upgrade u-value (force float)
            wallsUpgradeUValueElement.val(wallsUpgradeUValue);

            if(isNaN(wallsUpgradeUValue) || typeof wallsUpgradeUValue === "undefined" || wallsUpgradeUValue === ""){
                //Add error
                wallsUpgradeUValueElement.closest(".input-field").addClass("error");
                hasErrors = true;
            } else {
                //Remove any errors
                wallsUpgradeUValueElement.closest(".input-field").removeClass("error");

                //Save current u-value area
                moduleFormSaveField("wallsUpgradeUValue", wallsUpgradeUValue);
            }
            //#endregion
            break;

        //Roof Tab
        case 5:
            //#region Current fabric type
            const roofCurrentFabricElement = $(".wtp-dropdown#roofCurrentFabricType");
            const roofCurrentFabric = parseInt(roofCurrentFabricElement.find(".selected").data("value"));

            if(isNaN(roofCurrentFabric) || roofCurrentFabric === 0){
                //Add error
                roofCurrentFabricElement.addClass("error");
                hasErrors = true;
            } else {
                //Remove any errors
                roofCurrentFabricElement.removeClass("error");

                //Save walls current fabric type
                moduleFormSaveField("roofCurrentFabricType", roofCurrentFabric);
            }
            //#endregion

            //#region Upgrade type
            const roofUpgradeTypeElement = $(".wtp-dropdown#roofUpgradeType");
            const roofUpgradeType = parseInt(roofUpgradeTypeElement.find(".selected").data("value"));

            if(isNaN(roofUpgradeType) || roofUpgradeType === 0){
                //Add error
                roofUpgradeTypeElement.addClass("error");
                hasErrors = true;
            } else {
                //Remove any errors
                roofUpgradeTypeElement.removeClass("error");

                //Save floor upgrade type
                moduleFormSaveField("roofUpgradeType", roofUpgradeType);
            }
            //#endregion

            //#region Area
            const roofAreaElement = $("input[name=roofArea]");
            let roofArea = roofAreaElement.val();

            if(roofArea !== ""){
                roofArea = parseFloat(roofArea);
            }

            //Set area (force float)
            roofAreaElement.val(roofArea);

            if(isNaN(roofArea) || typeof roofArea === "undefined" || roofArea === ""){
                //Add error
                roofAreaElement.closest(".input-field").addClass("error");
                hasErrors = true;
            } else {
                //Remove any errors
                roofAreaElement.closest(".input-field").removeClass("error");

                //Save area
                moduleFormSaveField("roofArea", roofArea);
            }
            //#endregion

            //#region Upgrade wanted
            const roofUpgradeWantedElement = $(".wtp-dropdown#roofUpgradeWanted");
            const roofUpgradeWanted = roofUpgradeWantedElement.find(".selected").data("value");

            if(roofUpgradeWanted === 0 || roofUpgradeWanted === ""){
                //Add error
                roofUpgradeWantedElement.addClass("error");
                hasErrors = true;
            } else {
                //Remove any errors
                roofUpgradeWantedElement.removeClass("error");

                //Save floor upgrade type
                moduleFormSaveField("roofUpgradeWanted", roofUpgradeWanted);
            }
            //#endregion

            //#region Current u-value
            const roofCurrentUValueElement = $("input[name=roofCurrentUValue]");
            let roofCurrentUValue = roofCurrentUValueElement.val();

            if(roofCurrentUValue !== ""){
                roofCurrentUValue = parseFloat(roofCurrentUValue);
            }

            //Set roof current u-value (force float)
            roofCurrentUValueElement.val(roofCurrentUValue);

            if(isNaN(roofCurrentUValue) || typeof roofCurrentUValue === "undefined" || roofCurrentUValue === ""){
                //Add error
                roofCurrentUValueElement.closest(".input-field").addClass("error");
                hasErrors = true;
            } else {
                //Remove any errors
                roofCurrentUValueElement.closest(".input-field").removeClass("error");

                //Save current u-value area
                moduleFormSaveField("roofCurrentUValue", roofCurrentUValue);
            }
            //#endregion

            //#region Upgrade u-value
            const roofUpgradeUValueElement = $("input[name=roofUpgradeUValue]");
            let roofUpgradeUValue = roofUpgradeUValueElement.val();

            if(roofUpgradeUValue !== ""){
                roofUpgradeUValue = parseFloat(roofUpgradeUValue);
            }

            //Set roof upgrade u-value (force float)
            roofUpgradeUValueElement.val(roofUpgradeUValue);

            if(isNaN(roofUpgradeUValue) || typeof roofUpgradeUValue === "undefined" || roofUpgradeUValue === ""){
                //Add error
                roofUpgradeUValueElement.closest(".input-field").addClass("error");
                hasErrors = true;
            } else {
                //Remove any errors
                roofUpgradeUValueElement.closest(".input-field").removeClass("error");

                //Save current u-value area
                moduleFormSaveField("roofUpgradeUValue", roofUpgradeUValue);
            }
            //#endregion
            break;
    }


    return !hasErrors;
}



function buildingFabricIndividualElements(currentPage, nextPageExists, moduleForm){
    let hasErrors = false;

    switch (currentPage) {
        case 1:
            let noneSelectedErrorMessage = moduleForm.find("#building-fabric-individual-element-none-selected");

            const selectionElementSelectors = [
                'input[name=floorFabricElement]',
                'input[name=windowsFabricElement]',
                'input[name=wallsFabricElement]',
                'input[name=roofFabricElement]',
                'input[name=doorsFabricElement]'
            ]

            // Loop through each selection element, return true if any are selected
            const anySelected = selectionElementSelectors.some(selector => {
                return $(selector).is(":checked");
            });

            if (!anySelected) {
                noneSelectedErrorMessage.show();
                hasErrors = true;
            } else {
                noneSelectedErrorMessage.hide();

                // Save the selected elements
                moduleFormSaveField("floorFabricElementSelected", $("input[name=floorFabricElement]").is(":checked"), false);
                moduleFormSaveField("windowsFabricElementSelected", $("input[name=windowsFabricElement]").is(":checked"), false);
                moduleFormSaveField("wallsFabricElementSelected", $("input[name=wallsFabricElement]").is(":checked"), false);
                moduleFormSaveField("roofFabricElementSelected", $("input[name=roofFabricElement]").is(":checked"), false);
                moduleFormSaveField("doorsFabricElementSelected", $("input[name=doorsFabricElement]").is(":checked"));
            }
            break;
        case 2: // Building
            //Year of construction
            const yearOfConstructionElement = moduleForm.find("#individualYearOfConstruction");
            const yearOfConstruction = parseInt(yearOfConstructionElement.find(".selected").data("value"));

            if(isNaN(yearOfConstruction) || yearOfConstruction === 0){
                //Add error
                yearOfConstructionElement.addClass("error");
                hasErrors = true;
            } else {
                //Remove any errors
                yearOfConstructionElement.removeClass("error");

                //Save year of construction
                moduleFormSaveField("yearOfConstruction", yearOfConstruction);
            }
            break;
        case 3: // Floor
            // Get all floor units
            const floorUnits = $(".form-pages .page[data-page=3]:visible .inputted-units .unit-card");

            // Show error if no floor units
            if(floorUnits.length === 0){
                // Add error
                $(".form-pages .page[data-page=3]:visible .inputted-units .empty-state").addClass("error");
                hasErrors = true;
            } else {
                // Remove any errors
                $(".form-pages .page[data-page=3]:visible .inputted-units .empty-state").removeClass("error");
            }
            break;
        case 4: // Windows
            // Get all window units
            const windowUnits = $(".form-pages .page[data-page=4]:visible .inputted-units .unit-card");

            // Show error if no window units
            if(windowUnits.length === 0){
                // Add error
                $(".form-pages .page[data-page=4]:visible .inputted-units .empty-state").addClass("error");
                hasErrors = true;
            } else {
                // Remove any errors
                $(".form-pages .page[data-page=4]:visible .inputted-units .empty-state").removeClass("error");
            }
            break;
        case 5: // Walls
            // Get all wall units
            const wallUnits = $(".form-pages .page[data-page=5]:visible .inputted-units .unit-card");

            // Show error if no wall units
            if(wallUnits.length === 0){
                // Add error
                $(".form-pages .page[data-page=5]:visible .inputted-units .empty-state").addClass("error");
                hasErrors = true;
            } else {
                // Remove any errors
                $(".form-pages .page[data-page=5]:visible .inputted-units .empty-state").removeClass("error");
            }
            break;
        case 6: // Roof
            // Get all roof units
            const roofUnits = $(".form-pages .page[data-page=6]:visible .inputted-units .unit-card");

            // Show error if no wall units
            if(roofUnits.length === 0){
                // Add error
                $(".form-pages .page[data-page=6]:visible .inputted-units .empty-state").addClass("error");
                hasErrors = true;
            } else {
                // Remove any errors
                $(".form-pages .page[data-page=6]:visible .inputted-units .empty-state").removeClass("error");
            }
            break;
        case 7: // Doors
            // Get all door units
            const doorUnits = $(".form-pages .page[data-page=7]:visible .inputted-units .unit-card");

            // Show error if no door units
            if(doorUnits.length === 0){
                // Add error
                $(".form-pages .page[data-page=7]:visible .inputted-units .empty-state").addClass("error");
                hasErrors = true;
            } else {
                // Remove any errors
                $(".form-pages .page[data-page=7]:visible .inputted-units .empty-state").removeClass("error");
            }
            break;
        case 8: // Heating system
            const calculatorMethod = $(".form-pages .page[data-page=8]:visible .multi-step-form").data("method");

            let degreeDays = null;
            let demandAdjustmentFactor = null;

            // Check to see if complete screen is visible
            if ($("#heating-demand-calculator-complete").data("complete") === true) {
                degreeDays = $("#heatingDemandCalculatorCompleteDegreeDays");
                demandAdjustmentFactor = $("#heatingDemandCalculatorCompleteHeatingDemandAdjustmentFactor");
            } else {
                demandAdjustmentFactor = moduleForm.find("#heatingDemandCalculatorHeatingDemandAdjustmentFactor");

                if (calculatorMethod === "postcode-lookup") {
                    degreeDays = $("#heatingDemandCalculatorPostcodeDegreeDays");
                } else {
                    degreeDays = $("#heatingDemandCalculatorManualDegreeDays");
                }
            }

            let degreeDaysValue = parseFloat(degreeDays.val());

            if (degreeDays && degreeDaysValue !== 0) {
                // Hide error
                degreeDays.parent(".input-field").removeClass("error");

                // Save the degree days
                moduleFormSaveField("heatingSystemDegreeDays", degreeDaysValue);
            }

            let demandAdjustmentFactorValue = demandAdjustmentFactor.val();

            if (demandAdjustmentFactor && demandAdjustmentFactorValue !== "") {
                // Hide error
                demandAdjustmentFactor.parent(".input-field").removeClass("error");
                demandAdjustmentFactorValue = parseFloat(demandAdjustmentFactorValue);

                // Save the demand adjustment factor
                moduleFormSaveField("heatingSystemDemandAdjustmentFactor", demandAdjustmentFactorValue);
            } else {
                // Show error
                demandAdjustmentFactor.parent(".input-field").addClass("error");
                hasErrors = true;
            }
            break;

    }

    return !hasErrors;
}

export {buildingFabricWholeBuilding ,buildingFabricIndividualElements};