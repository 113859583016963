import {saveChargerUnit, deleteUnit} from "@/js/api/ev-charging-stations";

let body = $("body");

// Toggle input form
body.on('click', '.module-form[data-module=evChargingStations] .toggle-add-unit-form', function(e) {
    const form = $(this).closest(".unit-input-container").find(".unit-input-form");

    //Get state of form
    const isOpen = form.hasClass("open");

    if(isOpen){
        //Slide up
        form.slideUp("slow");

        //Close form
        form.removeClass("open");
    } else {
        //Slide down
        form.slideDown("slow");

        //Open form
        form.addClass("open");
    }
});

body.on('wtp-dropdown-change', '.module-form[data-module=evChargingStations] #chargerUnitType', function(e, selectedText, selectedValue, element) {
    const unitSize = $(this).closest(".unit-input-form").find("#chargerUnitSize");

    if (selectedValue !== "" && selectedValue.length > 0) {
        // Enable the size dropdown
        unitSize.removeClass("disabled");

        let selectedType = "";

        // Loop dropdown options
        unitSize.find(".dropdown-content li").each(function(){
            if ($(this).data("value") === unitSize.find(".selected").data("value")){
                selectedType = $(this).data("type");
            }

            if ($(this).data("type") === "" || $(this).data("type") === selectedValue){
                // Show option
                $(this).show();
            } else {
                // Hide option
                $(this).hide();
            }
        })

        // If the selected type is not the current type, reset the size dropdown
        if (selectedType !== selectedValue){
            unitSize.find(".selected").text("Select an option").data("value", "");
        }
    } else {
        // Disable the size dropdown
        unitSize.addClass("disabled");

        // Clear the selected value
        unitSize.find(".selected").text("Select an option").data("value", "");
    }
});

body.on('wtp-dropdown-change', '.module-form[data-module=evChargingStations] #chargerUnitSize', function(e, selectedText, selectedValue, element) {
    const form = $(this).closest(".unit-input-form");

    // Prefill the number of guns input
    const numberOfGuns = element.data("num-guns");
    form.find("#chargerUnitNumberOfGuns").val(numberOfGuns).closest(".input-field").removeClass("error");

    // Prefill the installation cost input
    const installationCost = element.data("install");
    form.find("#chargerUnitInstallationCost").val(installationCost).closest(".input-field").removeClass("error");

    // Prefill unit cost input
    const unitCost = element.data("cost");
    form.find("#chargerUnitCost").val(unitCost).closest(".input-field").removeClass("error");

    // Prefill payter unit cost input
    const payterCost = element.data("payter");
    form.find("#chargerUnitPayterTerminalCost").val(payterCost).closest(".input-field").removeClass("error");

    // Prefill power usage input
    const powerUsage = parseFloat(element.data("power-usage")) * 100;
    form.find("#chargerUnitPowerUsage").val(powerUsage).closest(".input-field").removeClass("error");

    // Prefill annual increase of usage input
    const annualIncreaseOfUsage = parseFloat(element.data("increase-of-usage")) * 100;
    form.find("#chargerUnitAnnualIncreaseOfUsage").val(annualIncreaseOfUsage).closest(".input-field").removeClass("error");
});

// Close input form
body.on('click', '.module-form[data-module=evChargingStations] .unit-input-container .unit-input-form .cancel-button, .module-form[data-module=evChargingStations] .unit-input-container .unit-input-form .close-unit-input-form', function(e) {
    const form = $(this).closest(".unit-input-container").find(".unit-input-form");

    // Slide up
    form.slideUp("slow");

    // Close form
    form.removeClass("open");
});

// Save unit input form
body.on('click', '.module-form[data-module=evChargingStations] #addEVChargingStationUnit .action-row .save-button', function(e) {
    // Get form
    const form = $(this).closest("#addEVChargingStationUnit");

    // Get the current page number
    const currentPage = form.data("current-page");

    // Validate the current page
    if (!validateUnitInputFormPage(form, currentPage)) {
        return;
    }

    // Submit the form
    form.submit();
});

// Handle form submission
body.on('submit', '.module-form[data-module=evChargingStations] #addEVChargingStationUnit', function(e) {
    e.preventDefault();

    const form = $(this);

    const formData = {
        type: $(this).find("#chargerUnitType .selected").data("value"),
        size: $(this).find("#chargerUnitSize .selected").data("value"),
        numberOfGun: $(this).find("#chargerUnitNumberOfGuns").val(),
        quantity: $(this).find("#chargerUnitQuantity").val(),
        numberOfCharges: $(this).find("#chargerUnitNumberOfChargesPerDayPerGun").val(),
        averageChargingTime: $(this).find("#chargerUnitAverageChargingTime").val(),
        powerUsage: $(this).find("#chargerUnitPowerUsage").val(),
        annualIncreaseOfUsage: $(this).find("#chargerUnitAnnualIncreaseOfUsage").val(),
        unitCost: $(this).find("#chargerUnitCost").val(),
        installationCost: $(this).find("#chargerUnitInstallationCost").val(),
        payterTerminalCost: $(this).find("#chargerUnitPayterTerminalCost").val(),
        managementAdminFee: $(this).find("#chargerUnitManagementAdminFee").val()
    }

    // Disable the action buttons
    $(this).find(".action-row button").prop("disabled", true);

    // Submit the unit
    saveChargerUnit(formData.type, formData.size, formData.numberOfGun, formData.quantity, formData.numberOfCharges, formData.averageChargingTime, formData.powerUsage, formData.annualIncreaseOfUsage, formData.unitCost, formData.installationCost, formData.payterTerminalCost, formData.managementAdminFee).then(function(response){
        // Clear the inputs
        form.find("input").val("");

        // Clear the dropdowns
        form.find(".wtp-dropdown .button .selected").text("Select an option").data("value", "");

        // Disable the size dropdown
        form.find("#chargerUnitSize").addClass("disabled");

        // Set quantity to 1
        form.find("#chargerUnitQuantity").val(1);

        // Enable the action buttons
        form.find(".action-row button").prop("disabled", false);

        // Set the current page to 0
        form.data("current-page", 0);

        // Hide all pages
        form.find(".unit-input-form-page").hide();

        // Show the first page
        form.find(".unit-input-form-page[data-page=0]").show();

        // Convert back button to cancel button
        form.find(".back-button").hide();
        form.find(".cancel-button").show();

        // Show next button
        form.find(".next-button").show();

        // Hide save button
        form.find(".save-button").hide();

        // Close the form
        form.closest(".unit-input-container").find(".unit-input-form").slideUp("slow").removeClass("open");

        const numberOfGunsSuffix = formData.numberOfGun > 1 ? "guns" : "gun";

        // Add the unit card
        $("#evChargingStationUnits").append(`<div class="unit-card"><input hidden type="hidden" name="cost" value="${formData.unitCost}"><input hidden type="hidden" name="installationCost" value="${formData.installationCost}"><input hidden type="hidden" name="payterTerminalCost" value="${formData.payterTerminalCost}"><input hidden type="hidden" name="quantity" value="${formData.quantity}"><div class="details"><div class="row"><span class="text">${formData.size} kW - ${formData.type.toUpperCase()}</span></div><div class="row"><span class="text subtle">${formData.numberOfGun} ${numberOfGunsSuffix}</span><span class="text subtle">Quantity: ${formData.quantity}</span></div></div><div class="actions"><button data-id="${response?.data?.uuid}" data-type="plug-in" class="delete-unit"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></button></div></div>`);

        // Hide empty state
        $("#evChargingStationUnits .empty-state").addClass("hide").removeClass("error");

        // Calculate total costs prefill
        calculateCostsPrefill();
    });
});

// Delete unit
body.on('click', '.module-form[data-module=evChargingStations] .inputted-units .unit-card .delete-unit', function(e) {
    // Confirm delete
    if(!confirm("Are you sure you want to delete this unit?")){
        return;
    }

    // Get unit card
    const unitCard = $(this).closest(".unit-card");

    // Get unit id
    const unitId = $(this).attr("data-id");

    // Get unit type
    const type = $(this).attr("data-type");

    let inputContainer = $(this).closest(".unit-input-container");

    // Remove unit card
    unitCard.remove();

    // Show empty state if needed
    if(inputContainer.find(".inputted-units .unit-card").length === 0){
        inputContainer.find(".inputted-units .empty-state").removeClass("hide");
    }

    // Delete unit
    deleteUnit(unitId, type).then(function(response){
        // Calculate total costs prefill
        calculateCostsPrefill();
    });
});

// Next page
body.on('click', '.module-form[data-module=evChargingStations] #addEVChargingStationUnit .action-row .next-button', function(e) {
    // Get form
    const form = $(this).closest("#addEVChargingStationUnit");

    // Get the current page number
    const currentPage = form.data("current-page");

    // Get the next page number
    const nextPage = currentPage + 1;

    // Get the current page
    const current = form.find(".unit-input-form-page[data-page=" + currentPage + "]");

    // Validate the current page
    if (!validateUnitInputFormPage(form, currentPage)) {
        return;
    }

    // Get the next page
    const next = form.find(".unit-input-form-page[data-page=" + nextPage + "]");

    if (next.length) {
        // Hide the current page
        current.hide();

        // Show the next page
        next.show();

        // Update the current page number
        form.data("current-page", nextPage);

        // Update the action buttons if needed
        if (nextPage > 0) {
            // Convert cancel button to back button
            form.find(".cancel-button").hide();

            // Show back button
            form.find(".back-button").show();

            if (nextPage === 2) {
                // Hide next button on last page
                form.find(".next-button").hide();

                // Show save button
                form.find(".save-button").show();
            } else {
                // Show next button
                form.find(".next-button").show();

                // Hide save button
                form.find(".save-button").hide();
            }
        } else {
            // Convert back button to cancel button
            form.find(".back-button").hide();

            // Show cancel button
            form.find(".cancel-button").show();
        }
    }
});

// Back button
body.on('click', '.module-form[data-module=evChargingStations] #addEVChargingStationUnit .action-row .back-button', function(e) {
    // Get form
    const form = $(this).closest("#addEVChargingStationUnit");

    // Get the current page number
    const currentPage = form.data("current-page");

    // Get the previous page number
    const previousPage = currentPage - 1;

    // Get the current page
    const current = form.find(".unit-input-form-page[data-page=" + currentPage + "]");

    // Get the previous page
    const previous = form.find(".unit-input-form-page[data-page=" + previousPage + "]");

    if (previous.length) {
        // Hide the current page
        current.hide();

        // Show the previous page
        previous.show();

        // Update the current page number
        form.data("current-page", previousPage);

        // Update the action buttons if needed
        if (previousPage > 0) {
            // Convert cancel button to back button
            form.find(".cancel-button").hide();

            // Show back button
            form.find(".back-button").show();

            if (previousPage === 3) {
                // Hide next button on last page
                form.find(".next-button").hide();

                // Show save button
                form.find(".save-button").show();
            } else {
                // Show next button
                form.find(".next-button").show();

                // Hide save button
                form.find(".save-button").hide();
            }
        } else {
            // Convert back button to cancel button
            form.find(".back-button").hide();

            // Show cancel button
            form.find(".cancel-button").show();
        }
    }
});

function calculateCostsPrefill(){
    let totalChargersCost = 0;
    let totalInstallationCost = 0;
    let totalPayterTerminalCost = 0;

    // Get the charger units
    const chargerUnits = $("#evChargingStationUnits .unit-card");

    // Loop through the charger units
    chargerUnits.each(function(){
        const unitCost = parseFloat($(this).find("input[name=cost]").val());
        const installationCost = parseFloat($(this).find("input[name=installationCost]").val());
        const payterTerminalCost = parseFloat($(this).find("input[name=payterTerminalCost]").val());
        const quantity = parseFloat($(this).find("input[name=quantity]").val());

        totalChargersCost += unitCost * quantity;
        totalInstallationCost += installationCost * quantity;
        totalPayterTerminalCost += payterTerminalCost * quantity;
    });

    // Set the total costs
    $("#totalChargersCost").val(totalChargersCost);
    $("#totalInstallationCost").val(totalInstallationCost);
    $("#totalPayterTerminalCost").val(totalPayterTerminalCost);
}

window.calculateCostsPrefill = calculateCostsPrefill;


// Validate current page
function validateUnitInputFormPage(form, currentPageNumber){
    // Find the current page
    const currentPage = form.find(".unit-input-form-page[data-page=" + currentPageNumber + "]");

    let isValid = true;

    switch (currentPageNumber) {
        case 0:
            // Type
            const type = currentPage.find("#chargerUnitType .selected").data("value");
            if (type === "" || type.length < 1){
                isValid = false;

                // Add error class
                currentPage.find("#chargerUnitType").addClass("error");
            } else {
                // Remove error class
                currentPage.find("#chargerUnitType").removeClass("error");
            }

            // Size
            const size = currentPage.find("#chargerUnitSize .selected").data("value");
            if ((size === "" || size.length < 1) && !currentPage.find("#chargerUnitSize").hasClass("disabled")){
                isValid = false;

                // Add error class
                currentPage.find("#chargerUnitSize").addClass("error");
            } else {
                // Remove error class
                currentPage.find("#chargerUnitSize").removeClass("error");
            }

            // Number of guns
            const numberOfGuns = currentPage.find("#chargerUnitNumberOfGuns").val();
            if (numberOfGuns === "" || numberOfGuns.length < 1 || numberOfGuns < 1){
                isValid = false;

                // Add error class
                currentPage.find("#chargerUnitNumberOfGuns").closest(".input-field").addClass("error");
            } else {
                // Remove error class
                currentPage.find("#chargerUnitNumberOfGuns").closest(".input-field").removeClass("error");
            }

            // Quantity
            const quantity = currentPage.find("#chargerUnitQuantity").val();
            if (quantity === "" || quantity.length < 1 || quantity < 1){
                isValid = false;

                // Add error class
                currentPage.find("#chargerUnitQuantity").closest(".input-field").addClass("error");
            } else {
                // Remove error class
                currentPage.find("#chargerUnitQuantity").closest(".input-field").removeClass("error");
            }
            break;
        case 1:
            // Number of charges
            const numberOfCharges = currentPage.find("#chargerUnitNumberOfChargesPerDayPerGun").val();
            if (numberOfCharges === "" || numberOfCharges.length < 1 || numberOfCharges < 1){
                isValid = false;

                // Add error class
                currentPage.find("#chargerUnitNumberOfChargesPerDayPerGun").closest(".input-field").addClass("error");
            } else {
                // Remove error class
                currentPage.find("#chargerUnitNumberOfChargesPerDayPerGun").closest(".input-field").removeClass("error");
            }

            // Average charging time
            const averageChargingTime = currentPage.find("#chargerUnitAverageChargingTime").val();
            if (averageChargingTime === "" || averageChargingTime.length < 1 || averageChargingTime < 0){
                isValid = false;

                // Add error class
                currentPage.find("#chargerUnitAverageChargingTime").closest(".input-field").addClass("error");
            } else {
                // Remove error class
                currentPage.find("#chargerUnitAverageChargingTime").closest(".input-field").removeClass("error");
            }

            // Power usage
            const powerUsage = currentPage.find("#chargerUnitPowerUsage").val();
            if (powerUsage === "" || powerUsage.length < 1 || powerUsage < 0){
                isValid = false;

                // Add error class
                currentPage.find("#chargerUnitPowerUsage").closest(".input-field").addClass("error");
            } else {
                // Remove error class
                currentPage.find("#chargerUnitPowerUsage").closest(".input-field").removeClass("error");
            }

            // Annual increase of usage
            const annualIncreaseOfUsage = currentPage.find("#chargerUnitAnnualIncreaseOfUsage").val();
            if (annualIncreaseOfUsage === "" || annualIncreaseOfUsage.length < 1 || annualIncreaseOfUsage < 0){
                isValid = false;

                // Add error class
                currentPage.find("#chargerUnitAnnualIncreaseOfUsage").closest(".input-field").addClass("error");
            } else {
                // Remove error class
                currentPage.find("#chargerUnitAnnualIncreaseOfUsage").closest(".input-field").removeClass("error");
            }
            break;
        case 2:
            // Unit cost
            const unitCost = currentPage.find("#chargerUnitCost").val();
            if (unitCost === "" || unitCost.length < 1 || unitCost < 0){
                isValid = false;

                // Add error class
                currentPage.find("#chargerUnitCost").closest(".input-field").addClass("error");
            } else {
                // Remove error class
                currentPage.find("#chargerUnitCost").closest(".input-field").removeClass("error");
            }

            // Installation cost
            const installationCost = currentPage.find("#chargerUnitInstallationCost").val();
            if (installationCost === "" || installationCost.length < 1 || installationCost < 0){
                isValid = false;

                // Add error class
                currentPage.find("#chargerUnitInstallationCost").closest(".input-field").addClass("error");
            } else {
                // Remove error class
                currentPage.find("#chargerUnitInstallationCost").closest(".input-field").removeClass("error");
            }

            // Payter terminal cost
            const payterTerminalCost = currentPage.find("#chargerUnitPayterTerminalCost").val();
            if (payterTerminalCost === "" || payterTerminalCost.length < 1 || payterTerminalCost < 0){
                isValid = false;

                // Add error class
                currentPage.find("#chargerUnitPayterTerminalCost").closest(".input-field").addClass("error");
            } else {
                // Remove error class
                currentPage.find("#chargerUnitPayterTerminalCost").closest(".input-field").removeClass("error");
            }

            // Management / administration cost
            const managementAdministrationCost = currentPage.find("#chargerUnitManagementAdminFee").val();
            if (managementAdministrationCost === "" || managementAdministrationCost.length < 1 || managementAdministrationCost < 0){
                isValid = false;

                // Add error class
                currentPage.find("#chargerUnitManagementAdminFee").closest(".input-field").addClass("error");
            } else {
                // Remove error class
                currentPage.find("#chargerUnitManagementAdminFee").closest(".input-field").removeClass("error");
            }
            break;
        default:
            return true;
    }

    return isValid;
}