import {
    addIndividualFloorUnit, addIndividualRoofUnit, addIndividualWallUnit,
    addIndividualWindowUnit, getPostcodeDegreeDays,
    deleteIndividualFloorUnit, deleteIndividualRoofUnit, deleteIndividualWallUnit,
    deleteIndividualWindowUnit
} from "@/js/api/building-fabric";

let body = $("body");


// Heating system calculation
body.on('input', '.module-form[data-module=buildingFabricIndividualElements] .form-pages .page[data-page=3] .input-form input[name=heatingSystemHours], .module-form[data-module=buildingFabricIndividualElements] .form-pages .page[data-page=3] .input-form input[name=heatingSystemDays], .module-form[data-module=buildingFabricIndividualElements] .form-pages .page[data-page=3] .input-form input[name=degreeDays]', function() {
    const page = $(this).closest('.page');

    // Get input values
    const hoursPerDay = parseFloat(page.find('.input-form input[name=heatingSystemHours]').val() ?? 0);
    const daysPerYear = parseFloat(page.find('.input-form input[name=heatingSystemDays]').val() ?? 0);
    const degreeDays = parseFloat(page.find('.input-form input[name=degreeDays]').val() ?? 0);

    // All inputs entered
    const allInputsEntered = hoursPerDay > 0 && daysPerYear > 0 && degreeDays > 0;

    // Total hours
    const totalHours = hoursPerDay * daysPerYear;

    // Heating days
    const heatingDays = totalHours / 24;

    // Degree days
    const degreeDaysCalc = degreeDays / 24;

    let heatingDemandAdjustment = 0;

    if (heatingDays > degreeDaysCalc) {
        heatingDemandAdjustment = 1;
    } else {
        heatingDemandAdjustment = heatingDays / degreeDaysCalc;
    }

    if (isNaN(heatingDemandAdjustment)) {
        heatingDemandAdjustment = 0;
    }

    // Update heating demand adjustment
    if (allInputsEntered) {
        page.find('.input-form input[name=heatingDemandAdjustmentFactor]').val(Math.round(heatingDemandAdjustment * 100) / 100);
    }
});

// On change of any element selection
body.on('change', '.module-form[data-module=buildingFabricIndividualElements] .form-pages .page[data-page=1] .input-form input', function() {
    // Get input name
    const inputName = $(this).attr('name');

    // Get input value
    const inputValue = $(this).is(":checked");

    switch(inputName){
        case "floorFabricElement":
            updateStageButtonStatus(3, !inputValue);
            break;
        case "windowsFabricElement":
            updateStageButtonStatus(4, !inputValue);
            break;
        case "wallsFabricElement":
            updateStageButtonStatus(5, !inputValue);
            break;
        case "roofFabricElement":
            updateStageButtonStatus(6, !inputValue);
            break;
        case "doorsFabricElement":
            updateStageButtonStatus(7, !inputValue);
            break;
    }
});

// On dropdown selection
body.on('click', '.module-form[data-module=buildingFabricIndividualElements] .wtp-dropdown .dropdown-content li', function(e) {
    let dropdown = $(this).closest(".wtp-dropdown");

    //Get building age letter
    const ageLetter = $(".wtp-dropdown#yearOfConstruction .selected").data("building-age-letter");

    //Standardise age letter
    let standardisedAgeLetter = ageLetter.trim().toLowerCase();

    //Get data attribute name
    const attributeName =  standardisedAgeLetter + "-age-u-value";

    switch(dropdown.data("dropdown-name")){
        case "windows-units-current-fabric-type":
            let windowUValue = $(this).data("u-value");

            //Update field
            $("#addBuildingFabricWindowsUnitsForm #windowsUnitCurrentUValue").val(windowUValue);
            break;
        case "floor-current-fabric-type":
            //Try and get U-value
            let floorUValue = $(this).data(attributeName);

            //Check to see if U-value exists
            if(floorUValue !== undefined && floorUValue !== null && floorUValue !== ""){
                //Update field
                $("#floorCurrentUValue").val(floorUValue);
            }
            break;
        case "walls-current-fabric-type":
            //Try and get U-value
            let wallsUValue = $(this).data(attributeName);

            //Check to see if U-value exists
            if(wallsUValue !== undefined && wallsUValue !== null && wallsUValue !== ""){
                //Update field
                $("#wallsCurrentUValue").val(wallsUValue);
            }
            break;
        case "roof-current-fabric-type":
            //Try and get U-value
            let roofUValue = $(this).data(attributeName);

            //Check to see if U-value exists
            if(roofUValue !== undefined && roofUValue !== null && roofUValue !== ""){
                //Update field
                $("#roofCurrentUValue").val(roofUValue);
            }
            break;
    }
});

// Update selected building age letter
body.on('click', '.module-form[data-module=buildingFabricIndividualElements] .wtp-dropdown#individualYearOfConstruction .dropdown-content li', function(e) {
    let dropdown = $(this).closest(".wtp-dropdown");

    // Get age letter
    let ageLetter = $(this).data("building-age-letter");

    // Get thermal mass factor
    let thermalMassFactor = $(this).data("thermal-mass-factor");

    // Update field
    dropdown.find(".selected").data("building-age-letter", ageLetter);

    // Update thermal mass factor
    dropdown.find(".selected").data("thermal-mass-factor", thermalMassFactor);
});

// Floor form - upgrade type change
body.on('click', '.module-form[data-module=buildingFabricIndividualElements] .wtp-dropdown.upgrade-wanted-dropdown .dropdown-content li', function(e) {
    // Get selected upgrade type
    let value = $(this).data("value");

    // Get input-form
    let inputForm = $(this).closest(".input-form");

    if (value === "yes") {
        // Show upgrade form
        inputForm.find(".upgrade-only-field").show();
    } else {
        // Hide upgrade form
        inputForm.find(".upgrade-only-field").hide();
    }
});

// Get u-value for selected fabric type
body.on('click', '.module-form[data-module=buildingFabricIndividualElements] .wtp-dropdown .dropdown-content li', function(e) {
    let dropdown = $(this).closest(".wtp-dropdown");

    //Get building age letter
    const ageLetter = $(".module-form[data-module=buildingFabricIndividualElements] .wtp-dropdown#individualYearOfConstruction .selected").data("building-age-letter");

    //Standardise age letter
    let standardisedAgeLetter = ageLetter.trim().toLowerCase();

    //Get data attribute name
    const attributeName =  standardisedAgeLetter + "-age-u-value";


    switch(dropdown.data("dropdown-name")){
        case "individual-floor-units-current-fabric-type":
            let floorUValue = $(this).data(attributeName);

            // Update field
            $("#addBuildingFabricIndividualFloorUnitsForm #individualFloorUnitCurrentUValue").val(floorUValue);
            break;
        case "individual-wall-units-current-fabric-type":
            let wallUValue = $(this).data(attributeName);

            // Update field
            $("#addBuildingFabricIndividualWallUnitsForm #individualWallUnitCurrentUValue").val(wallUValue);
            break;
        case "individual-roof-units-current-fabric-type":
            let roofUValue = $(this).data(attributeName);

            // Update field
            $("#addBuildingFabricIndividualRoofUnitsForm #individualRoofUnitCurrentUValue").val(roofUValue);
            break;
        case "individual-windows-units-current-fabric-type":
            let windowUValue = $(this).data("u-value");


            // Update field
            $("#addBuildingFabricIndividualWindowsUnitsForm #individualWindowsUnitCurrentUValue").val(windowUValue);
            break;
    }
});

// Save individual units form
body.on('submit', '.module-form[data-module=buildingFabricIndividualElements] .individual-units-form', function(e) {
    e.preventDefault();

    let form = $(this);
    let element = form.data("element");

    // Hide errors
    form.find(".errors").hide();

    // Disable submit button
    form.find("button[type=submit]").prop("disabled", true);

    const currentFabricType = form.find(".current-fabric-type-input .selected").data("value");
    const area = form.find(".area-input").val();
    const upgradeWanted = form.find(".upgrade-wanted-input .selected").data("value");
    const upgradeType = form.find(".upgrade-type-input .selected").data("value");
    const currentUValue = form.find(".current-u-value-input").val();
    const upgradeUValue = form.find(".upgrade-u-value-input").val();

    function handleError(error) {
        //Enable submit button
        form.find("button[type=submit]").prop("disabled", false);

        //Clear errors
        form.find(".errors .errors-list").html("");

        //Loop through errors
        $.each(error.data, function (key, value) {
            //Add error to list
            form.find(".errors .errors-list").append("<li class=\"error-item\">" + sanitizeHtml(value) + "</li>");
        });

        //Show errors
        form.find(".errors").show();
    }

    function handleSuccess(response, fabricType) {
        const data = response.data;

        // Clear form
        form[0].reset();

        // Prefill required fields
        form.find(".current-u-value-input").val(form.find(".current-u-value-input").data("default-value"));
        form.find(".upgrade-u-value-input").val(form.find(".upgrade-u-value-input").data("default-value"));

        // Reset dropdowns
        form.find(".wtp-dropdown .selected").text("Select an option").data("value", "");

        // Enable submit button
        form.find("button[type=submit]").prop("disabled", false);

        const formContainer = form.closest(".unit-input-form");

        // Close form
        formContainer.slideUp("slow");
        formContainer.removeClass("open");

        const unitList = form.closest(".unit-input-container").find(".inputted-units");

        // Generate html for unit card
        let html = "";

        switch (fabricType) {
            case "windows":
                html = `<div class="unit-card"><div class="details"><div class="row"><span class="text">${sanitizeHtml(data.currentFabricTypeName)}</span></div><div class="row"><span class="text subtle">Area: ${sanitizeHtml(data.area)}m<sup>2</sup></span></div><div class="row"><span class="text subtle">Current U-Value: ${sanitizeHtml(data.currentUValue)}</span></div><div class="row"><span class="text subtle">Upgrade?: <span style="text-transform: capitalize;">${sanitizeHtml(data.upgradeWanted)}</span></span></div>${(data.upgradeWanted === "yes" ? `<div class="row"><span class="text subtle">Upgrade U-Value: ${sanitizeHtml(data.upgradeUValue)}</span></div>` : ``)}</div><div class="actions"><button data-id="${sanitizeHtml(data.uuid)}" class="delete-unit"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></button></div></div>`;
                break;
            case "floor":
            case "wall":
            case "roof":
            default:
                html = `<div class="unit-card"><div class="details"><div class="row"><span class="text">${sanitizeHtml(data.currentFabricTypeName)} ${(data.upgradeWanted === "yes" ? (` - ${data.upgradeTypeName}`) : (""))}</span></div><div class="row"><span class="text subtle">Area: ${sanitizeHtml(data.area)}m<sup>2</sup></span></div><div class="row"><span class="text subtle">Current U-Value: ${sanitizeHtml(data.currentUValue)}</span></div><div class="row"><span class="text subtle">Upgrade?: <span style="text-transform: capitalize;">${sanitizeHtml(data.upgradeWanted)}</span></span></div>${(data.upgradeWanted === "yes" ? `<div class="row"><span class="text subtle">Upgrade U-Value: ${sanitizeHtml(data.upgradeUValue)}</span></div>` : ``)}</div><div class="actions"><button data-id="${sanitizeHtml(data.uuid)}" class="delete-unit"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></button></div></div>`;
        }

        // Add unit to list
        unitList.append(html);

        // Remove empty state if needed
        unitList.find(".empty-state").addClass("hide").removeClass("error");
    }

    // Send to server
    switch (element){
        case "roof":
            addIndividualRoofUnit(currentFabricType, area, upgradeWanted, upgradeType, currentUValue, upgradeUValue).then((response) => handleSuccess(response, element)).catch(handleError);
            break
        case "wall":
            addIndividualWallUnit(currentFabricType, area, upgradeWanted, upgradeType, currentUValue, upgradeUValue).then((response) => handleSuccess(response, element)).catch(handleError);
            break;
        case "floor":
            addIndividualFloorUnit(currentFabricType, area, upgradeWanted, upgradeType, currentUValue, upgradeUValue).then((response) => handleSuccess(response, element)).catch(handleError);
            break;
        case "windows":
            addIndividualWindowUnit(currentFabricType, area, upgradeWanted, upgradeType, currentUValue, upgradeUValue).then((response) => handleSuccess(response, element)).catch(handleError);
            break;
    }
});

//Delete unit
body.on('click', '.module-form[data-module=buildingFabricIndividualElements] .inputted-units .unit-card .delete-unit', function(e) {
    //Confirm delete
    if(!confirm("Are you sure you want to delete this unit?")){
        return;
    }

    //Get unit card
    const unitCard = $(this).closest(".unit-card");

    //Get unit id
    const unitId = $(this).attr("data-id");

    let page = $(this).closest(".page");

    //Remove unit card
    unitCard.remove();

    //Show empty state if needed
    if(page.find(".inputted-units .unit-card").length === 0){
        page.find(".inputted-units .empty-state").removeClass("hide");
    }

    // Get page number
    const pageNumber = page.data("page");

    // Remove unit on server-side
    switch (pageNumber) {
        case 6:
            deleteIndividualRoofUnit(unitId);
            break;
        case 5:
            deleteIndividualWallUnit(unitId);
            break;
        case 4:
            deleteIndividualWindowUnit(unitId);
            break;
        case 3:
        default:
            deleteIndividualFloorUnit(unitId);
            break;
    }
});


//#region Heating demand adjustment factor calculator

// Method selection
body.on('click', '.module-form[data-module=buildingFabricIndividualElements] .multi-step-form .method-selection .method-selection-button', function(e) {
    // Get the value
    const value = $(this).data("value");

    // Multistep form
    const multiStepForm = $(this).closest(".multi-step-form");

    // Set the value
    multiStepForm.data("method", value);

    // Go to next page
    multiStepFormNextPage(multiStepForm);
});

// Next step
body.on('click', '.module-form[data-module=buildingFabricIndividualElements] .multi-step-form .progress-controls .next-step-button', async function(e) {
    // Multistep form
    const multiStepForm = $(this).closest(".multi-step-form");

    // Go to the next page
    await multiStepFormNextPage(multiStepForm);
});

// Previous step
body.on('click', '.module-form[data-module=buildingFabricIndividualElements] .multi-step-form .progress-controls .previous-step-button', function(e) {
    // Multistep form
    const multiStepForm = $(this).closest(".multi-step-form");

    // Go to the previous page
    multiStepFormPreviousStep(multiStepForm);
});

// Remove error on input
body.on('input', '.module-form[data-module=buildingFabricIndividualElements] .multi-step-form .step-form-page input', async function(e) {
    // Remove error class
    $(this).closest(".input-field").removeClass("error");

    // Hide error message
    $(this).closest(".field").find(".error-message").removeClass("show").text("");
});

// On change of last page inputs, recalculate heating demand adjustment factor
body.on('input', '.module-form[data-module=buildingFabricIndividualElements] .multi-step-form .step-form-page[data-page=2] input', async function(e) {
    // Multistep form
    const multiStepForm = $(this).closest(".multi-step-form");

    // If in the hidden assumptions section, set as edited
    if ($(this).parents(".assumption-fields").length > 0){
        $(this).data("edited", "true");

        // Enable the reset button
        $("#reset-heating-demand-calculator-assumptions").prop("disabled", false);
    }

    if ($(this).attr("id") === "heatingDemandCalculatorHeatingDemandAdjustmentFactor"){
        // Validate the form but don't recalculate
        await validateMultiStepForm(multiStepForm, false);
    } else {
        // Validate the form
        await validateMultiStepForm(multiStepForm);
    }
});

// Reset heating demand calculator assumptions
body.on('click', '#reset-heating-demand-calculator-assumptions', function(e) {
    // Clear all edited attributes
    $(".module-form[data-module=buildingFabricIndividualElements] .multi-step-form .step-form-page[data-page=2] .assumption-fields input").each(function() {
        $(this).data("edited", "false");

        // Check if it has an original value and set it
        if ($(this).data("original-value") !== undefined){
            $(this).val($(this).data("original-value"));
        }
    });

    // Disable the reset button
    $(this).prop("disabled", true);

    // Recalculate heating demand adjustment factor
    calculateHeatingDemandAdjustmentFactor();
});

// Reset calculator
body.on('click', '#reset-heating-demand-calculator', function(e) {
    // Set the current page to 0
    $(".module-form[data-module=buildingFabricIndividualElements] .multi-step-form").data("current-page", 0).data("method", "any");

    // Show the first page
    $(".module-form[data-module=buildingFabricIndividualElements] .multi-step-form .step-form-page[data-page=0]").show();

    // Hide complete page
    $(".module-form[data-module=buildingFabricIndividualElements] .multi-step-form #heating-demand-calculator-complete").hide().data("complete", false);
});

async function multiStepFormNextPage(multiStepForm) {
    // Get the current page
    const currentPage = multiStepForm.data("current-page");

    // Get the method
    const method = multiStepForm.data("method");

    // Try and find the next page
    const possibleNextPage = currentPage + 1;

    let nextPage = null;

    // Check if the current page is valid
    const isValid = await validateMultiStepForm(multiStepForm);
    if (!isValid){
        return;
    }

    // Loop through all the page
    multiStepForm.find(`.step-form-page`).each(function() {
        const stepMethod = $(this).data("method");

        if ((stepMethod === "any" || method === stepMethod) && $(this).data("page") === possibleNextPage) {
            nextPage = $(this);
        }
    });

    if (nextPage){
        // Hide all steps
        multiStepForm.find(`.step-form-page`).hide();

        // Show next step
        nextPage.show();

        // Show progress controls
        if (possibleNextPage > 0){
            if (possibleNextPage === 2){
                // Hide the next button
                multiStepForm.find(".progress-controls .next-step-button").hide();

                // Get thermal mass adjustment factor
                const thermalMassAdjustmentFactor = $("#individualYearOfConstruction .selected").data("thermal-mass-factor");

                // Update thermal mass adjustment factor
                multiStepForm.find("#heatingDemandCalculatorThermalMassAdjustmentFactor").val(thermalMassAdjustmentFactor);
                multiStepForm.find("#heatingDemandCalculatorThermalMassAdjustmentFactor").data("original-value", thermalMassAdjustmentFactor);
            } else {
                // Show the next button
                multiStepForm.find(".progress-controls .next-step-button").show();
            }

            multiStepForm.find(".progress-controls").show();
        } else {
            multiStepForm.find(".progress-controls").hide();
        }

        // Update current step
        multiStepForm.data("current-page", possibleNextPage);
    }
}

function multiStepFormPreviousStep(multiStepForm) {
    // Get the current page
    const currentPage = multiStepForm.data("current-page");

    if (currentPage < 1){
        return;
    }

    // Get the method
    const method = multiStepForm.data("method");

    // Try and find the next page
    const possiblePreviousPage = currentPage - 1;

    let previousPage = null;

    // Loop through all the page
    multiStepForm.find(`.step-form-page`).each(function() {
        const stepMethod = $(this).data("method");

        if ((stepMethod === "any" || method === stepMethod) && $(this).data("page") === possiblePreviousPage) {
            previousPage = $(this);
        }
    });

    if (previousPage){
        // Hide all steps
        multiStepForm.find(`.step-form-page`).hide();

        // Show next step
        previousPage.show();

        // Show progress controls
        if (possiblePreviousPage > 0){
            multiStepForm.find(".progress-controls").show();

            // Make sure the next button is visible
            multiStepForm.find(".progress-controls .next-step-button").show();
        } else {
            multiStepForm.find(".progress-controls").hide();
        }

        // Update current step
        multiStepForm.data("current-page", possiblePreviousPage);
    }
}

async function validateMultiStepForm(multiStepForm, reCalculate = true) {
    // Disable all progress controls buttons
    multiStepForm.find(".progress-controls button").prop("disabled", true);

    // Get the current page
    const currentPage = multiStepForm.data("current-page");

    // Get the method
    const method = multiStepForm.data("method");

    // Get the current page
    const page = multiStepForm.find(`.step-form-page[data-page=${currentPage}][data-method=any], .step-form-page[data-page=${currentPage}][data-method=${method}]`);

    // Get the method
    const pageMethod = page.data("method");

    // Check if the method is correct
    if (pageMethod !== "any" && method !== pageMethod){
        return false;
    }

    // Check if all inputs are correct
    let valid = true;

    // Page specific validation
    switch (currentPage){
        default:
        case 0:
            break;
        case 1:
            if (method === "postcode-lookup"){ // Postcode lookup
                const postcodeInput = page.find("#heatingDemandCalculatorPropertyPostcode");
                const postcodeField = postcodeInput.closest(".input-field");
                const postcode = postcodeInput.val();

                // Check to make sure postcode isn't empty
                if (postcode.length === 0){
                    valid = false;

                    // Add error to postcode
                    postcodeField.addClass("error");
                    postcodeField.closest(".field").find(".error-message").addClass("show").text("Please enter the property's postcode.");
                    break;
                }

                // Check postcode to see if it has degree days
                try {
                    const response = await getPostcodeDegreeDays(postcode);

                    // Set degree days
                    const degreeDays = response.data.degreeDays;

                    // Update degree days
                    page.find("#heatingDemandCalculatorPostcodeDegreeDays").val(degreeDays);
                } catch (error) {
                    valid = false;

                    // Add error to postcode
                    postcodeField.addClass("error");
                    postcodeField.closest(".field").find(".error-message").addClass("show").text("No degree days could be found for the given postcode. Please try again, or go back and use manual entry.");
                    break;
                }

                // Remove error
                postcodeField.removeClass("error");
                postcodeField.closest(".field").find(".error-message").removeClass("show").text("");
            } else { // Manual entry
                const degreeDaysInput = page.find("#heatingDemandCalculatorManualDegreeDays");
                const degreeDaysField = degreeDaysInput.closest(".input-field");
                const degreeDays = degreeDaysInput.val();

                // Check to make sure degree days isn't empty
                if (!degreeDaysInput || !degreeDays || degreeDays.length === 0){
                    valid = false;

                    // Add error to degree days
                    degreeDaysField.addClass("error");
                    break;
                }

                const baseTempInput = page.find("#heatingDemandCalculatorManualBaseTemp");
                const baseTempField = baseTempInput.closest(".input-field");
                const baseTemp = baseTempInput.val();

                // Check to make sure base temperature isn't empty
                if (!baseTempInput || !baseTemp || baseTemp.length === 0){
                    valid = false;

                    // Add error to base temperature
                    baseTempField.addClass("error");
                    break;
                }

                const heatingDaysInput = page.find("#heatingDemandCalculatorManualHeatingDays");
                const heatingDaysField = heatingDaysInput.closest(".input-field");
                const heatingDays = heatingDaysInput.val();

                // Check to make sure heating days isn't empty
                if (!heatingDaysInput || !heatingDays || heatingDays.length === 0){
                    valid = false;

                    // Add error to heating days
                    heatingDaysField.addClass("error");
                    break;
                }
            }
            break;
        case 2:
            // Heating set point
            const heatingSetPointInput = page.find("#heatingDemandCalculatorHeatingSetPoint");
            const heatingSetPointField = heatingSetPointInput.closest(".input-field");
            const heatingSetPoint = heatingSetPointInput.val();

            if (heatingSetPoint.length === 0 || heatingSetPoint < 0){
                valid = false;

                // Add error to heating set point
                heatingSetPointField.addClass("error");
            } else {
                // Remove error
                heatingSetPointField.removeClass("error");
            }

            // Hours per day
            const hoursPerDayInput = page.find("#heatingDemandCalculatorHoursPerDay");
            const hoursPerDayField = hoursPerDayInput.closest(".input-field");
            const hoursPerDay = hoursPerDayInput.val();

            if (hoursPerDay.length === 0 || hoursPerDay < 0){
                valid = false;

                // Add error to hours per day
                hoursPerDayField.addClass("error");
            } else {
                // Remove error
                hoursPerDayField.removeClass("error");
            }

            if (reCalculate){
                calculateHeatingDemandAdjustmentFactor();
            }
            break;
    }

    // Enable all progress controls buttons, remove attribute
    multiStepForm.find(".progress-controls button").prop("disabled", false);

    return valid;
}

function totalUValue() {
    let totalArea = 0;
    let totalUValue = 0;

    // Floor
    if (!checkIfPageIsDisabled(3)){
        // Loop through all floor units
        $(".module-form[data-module=buildingFabricIndividualElements] .form-pages .page[data-page=3] .inputted-units .unit-card").each(function() {
            // Get area
            const area = parseFloat($(this).find(".details .row:nth-child(2) span").text().replace("Area: ", "").replace("m²", ""));

            // Get U-Value
            const uValue = parseFloat($(this).find(".details .row:nth-child(3) span").text().replace("Current U-Value: ", ""));

            // Add to total area
            totalArea += area;

            // Add to total U-Value
            totalUValue += uValue;
        });
    }

    // Windows
    if (!checkIfPageIsDisabled(4)){
        // Loop through all window units
        $(".module-form[data-module=buildingFabricIndividualElements] .form-pages .page[data-page=4] .inputted-units .unit-card").each(function() {
            // Get area
            const area = parseFloat($(this).find(".details .row:nth-child(2) span").text().replace("Area: ", "").replace("m²", ""));

            // Get U-Value
            const uValue = parseFloat($(this).find(".details .row:nth-child(3) span").text().replace("Current U-Value: ", ""));

            // Add to total area
            totalArea += area;

            // Add to total U-Value
            totalUValue += uValue;
        });
    }

    // Walls
    if (!checkIfPageIsDisabled(5)){
        // Loop through all wall units
        $(".module-form[data-module=buildingFabricIndividualElements] .form-pages .page[data-page=5] .inputted-units .unit-card").each(function() {
            // Get area
            const area = parseFloat($(this).find(".details .row:nth-child(2) span").text().replace("Area: ", "").replace("m²", ""));

            // Get U-Value
            const uValue = parseFloat($(this).find(".details .row:nth-child(3) span").text().replace("Current U-Value: ", ""));

            // Add to total area
            totalArea += area;

            // Add to total U-Value
            totalUValue += uValue;
        });
    }

    // Roof
    if (!checkIfPageIsDisabled(6)){
        // Loop through all roof units
        $(".module-form[data-module=buildingFabricIndividualElements] .form-pages .page[data-page=6] .inputted-units .unit-card").each(function() {
            // Get area
            const area = parseFloat($(this).find(".details .row:nth-child(2) span").text().replace("Area: ", "").replace("m²", ""));

            // Get U-Value
            const uValue = parseFloat($(this).find(".details .row:nth-child(3) span").text().replace("Current U-Value: ", ""));

            // Add to total area
            totalArea += area;

            // Add to total U-Value
            totalUValue += uValue;
        });
    }

    // Doors
    if (!checkIfPageIsDisabled(7)){
        // Loop through all door units
        $(".module-form[data-module=buildingFabricIndividualElements] .form-pages .page[data-page=7] .inputted-units .unit-card").each(function() {
            // Get area
            const area = parseFloat($(this).find(".details .row:nth-child(2) span").text().replace("Area: ", "").replace("m²", ""));

            // Get U-Value
            const uValue = parseFloat($(this).find(".details .row:nth-child(3) span").text().replace("Current U-Value: ", ""));

            // Add to total area
            totalArea += area;

            // Add to total U-Value
            totalUValue += uValue;
        });
    }

    return totalUValue * totalArea;
}

function calculateHeatingDemandAdjustmentFactor(){
    const page = $(".module-form[data-module=buildingFabricIndividualElements] .multi-step-form .step-form-page[data-page=2]");
    const method = page.closest(".multi-step-form").data("method");

    // Get input values
    const heatingSetPoint = parseFloat(page.find('#heatingDemandCalculatorHeatingSetPoint').val() ?? 0);
    const hoursPerDay = parseFloat(page.find('#heatingDemandCalculatorHoursPerDay').val() ?? 0);

    // Calculate average external temperature
    const averageExternalTemperatureInput = page.find('#heatingDemandCalculatorAverageExternalTemp');

    if (averageExternalTemperatureInput.data("edited") !== "true"){
        let averageExternalTemperature = 0;

        // Get average external temperature (from spreadsheet)
        if (method === "postcode-lookup"){
            averageExternalTemperature = 5.12;
        } else {
            // Todo: add manual entry calculation (not a static figure)
            averageExternalTemperature = 5.12;
        }

        // Update average external temperature (rounded to 2 decimal places)
        averageExternalTemperatureInput.val(Math.round(averageExternalTemperature * 100) / 100);
    } else {
        // Make sure it is not empty
        if (averageExternalTemperatureInput.val().length === 0){
            // Add error to average external temperature
            averageExternalTemperatureInput.closest(".input-field").addClass("error");
        } else {
            // Remove error
            averageExternalTemperatureInput.closest(".input-field").removeClass("error");
        }
    }

    // Thermal mass adjustment factor
    const thermalMassAdjustmentFactor = page.find('#heatingDemandCalculatorThermalMassAdjustmentFactor');

    // Get total value area
    const totalUValueArea = totalUValue();

    let degreeDays = 0;

    // If method is postcode lookup, get degree days from input
    if (method === "postcode-lookup"){
        // Get degree days
        degreeDays = parseFloat($('#heatingDemandCalculatorPostcodeDegreeDays').val() ?? 0);
    } else {
        // Get manual degree days
        degreeDays = parseFloat($('#heatingDemandCalculatorManualDegreeDays').val() ?? 0);
    }

    // Calculate total heat loss
    const totalHeatLoss = totalUValueArea * degreeDays * 0.024;

    // Adjusted Heat Lost (adjusted for hours heating system is on, thermal mass of property)
    const adjustedHeatLoss = (totalUValueArea * degreeDays * (heatingSetPoint - parseFloat(averageExternalTemperatureInput.val())) * hoursPerDay / (1000 * 24)) * parseFloat(thermalMassAdjustmentFactor.val());

    // Calculate heating demand adjustment factor
    const heatingDemandAdjustmentFactorInput = page.find('#heatingDemandCalculatorHeatingDemandAdjustmentFactor');

    const heatingDemandAdjustmentFactor = (adjustedHeatLoss/totalHeatLoss) ?? 0;

    // Update heating demand adjustment factor
    heatingDemandAdjustmentFactorInput.val(Math.round(heatingDemandAdjustmentFactor * 100) / 100);
}

function checkIfPageIsDisabled(pageNum){
    // Get page
    const page = $(`.module-form[data-module=buildingFabricIndividualElements] .form-progress-v2 .stage-button[data-page=${pageNum}]`);

    // Check if page is disabled
    return page.hasClass("disabled");
}

//#endregion


function updateStageButtonStatus(pageNum, isDisabled){
    // Get stage button
    const stageButton = $(`.module-form[data-module=buildingFabricIndividualElements] .form-progress-v2 .stages .stage-button[data-page=${pageNum}]`);

    // Update button status
    if(isDisabled){
        stageButton.addClass("disabled show-na");
    } else {
        stageButton.removeClass("disabled show-na");
    }
}


