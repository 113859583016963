import {apiRequest} from "@/js/api/index";

export function saveChargerUnit(type, size, numberOfGun, quantity, numberOfCharges, averageChargingTime, powerUsage, annualIncreaseOfUsage, unitCost, installationCost, payterTerminalCost, managementAdminFee){
    return new Promise(function(resolve, reject){
        apiRequest('evChargingStations', 'saveChargerUnit', {
            type,
            size,
            numberOfGun,
            quantity,
            numberOfCharges,
            averageChargingTime,
            powerUsage,
            annualIncreaseOfUsage,
            unitCost,
            installationCost,
            payterTerminalCost,
            managementAdminFee
        }).then(function(response) {
            resolve(response);
        }).catch(function(error) {
            reject(error);
        })
    });
}

export function deleteUnit(uuid){
    return new Promise(function(resolve, reject){
        apiRequest('evChargingStations', 'deleteUnit', {
            uuid: uuid,
        }).then(function(response) {
            resolve(response);
        }).catch(function(error) {
            console.log(error);
            alert(error?.message || error?.responseText);
            reject(error);
        })
    });
}